import {submitForm} from '../FormAjaxSave';
import * as Dialogs from "../Dialogs";
import {del} from "../Network/Network";

let disposables = [];

function getForm() {
  return document.getElementById('guestbook-form');
}

function getHeadline() {
  return document.getElementById('guestbook-headline-add');
}

function toggleAdd() {
  const form = getForm();
  if (form.style.display === 'none') {
    form.style.display = 'block';
    form.querySelector('input').focus();
  } else {
    form.style.display = 'none';
  }
}

function bindEntryDelete() {
  [...document.querySelectorAll('.js-guestbook-delete')].forEach((delBtn) => {
    const handler = () => {
      const id = parseInt(delBtn.getAttribute('data-id'), 10);
      const div = document.getElementById(`guestbook-entry--${id}`);
      const name = div.querySelector('.guestbook-entry-name').textContent;
      Dialogs.confirm(`Möchtest du den Beitrag von '${name}' wirklich löschen?`).then((conf) => {
        if (conf) {
          del('guestbook_delete', {id}).then(() => div.remove());
        }
      });
    };
    delBtn.addEventListener('click', handler);
    disposables.push(() => delBtn.removeEventListener('click', handler));
  });
}

window.guestbookRecaptchaSuccess = () => submitForm(getForm());

export function init() {
  destroy();
  if (getForm()) {
    const headline = getHeadline();
    headline.addEventListener('click', toggleAdd);
    disposables.push(() => headline.removeEventListener('click', toggleAdd));

    const capt = document.querySelector('.g-recaptcha');
    const sitekey = capt.getAttribute('data-sitekey');
    if (sitekey && !document.querySelector('.grecaptcha-badge')) {
      grecaptcha.render(capt, {sitekey, callback: capt.getAttribute('data-callback')});
    }

    bindEntryDelete();
  }
}

export function destroy() {
  disposables.forEach((fn) => fn());
  disposables = [];
}
