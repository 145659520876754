import {error} from '../Logger';
import {generateUrl, post} from '../Network/Network';

let dialog, image, xhr;

/**
 * @param {String} url
 * @param {File} file
 * @param {Object} data
 * @param {Function} onProgress
 * @returns {Promise} extended by an abort-method
 */
function submitFile(url, file, data, onProgress) {
  const xhr = new XMLHttpRequest();

  const prom = new Promise((resolve, reject) => {
    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status === 200) {
          resolve(JSON.parse(xhr.responseText));
        } else {
          reject(xhr.statusText);
        }
      }
    };

    if (typeof onProgress === 'function') {
      xhr.onprogress = onProgress;
    }

    xhr.open('POST', generateUrl(url), true);

    data = data || {};
    const formData = new FormData();
    Object.keys(data).forEach((key) => formData.append(key, data[key]));
    formData.append('image', file);
    xhr.send(formData);
  });

  prom.abort = () => xhr.abort();

  return prom;
}

function cancelupload() {
  if (xhr) {
    xhr.abort();
  }
  dialog.state('empty');
}

function clear() {
  dialog.clear();
  image = null;
}

function setImageResponse({size, url}) {
  image = {
    size,
    url: url + '?_ignore=' + Date.now(),
  };
  dialog.populate(image.url, image.size);
}

function fileready(event) {
  dialog.state('uploading');
  dialog.progress(0);
  const onProgress = (ev) => dialog.progress((ev.loaded / ev.total) * 100);
  xhr = submitFile('editor.image.upload', event.detail().file, null, onProgress)
    .then(setImageResponse)
    .catch((err) => error(err) && new ContentTools.FlashUI('no'))
    .then(() => xhr = null);
}

function rotate(clockwise) {
  dialog.busy(true);
  post('editor.image.rotate', null, {clockwise: clockwise ? 1 : 0, url: image.url})
    .then(setImageResponse)
    .catch((err) => error(err) && new ContentTools.FlashUI('no'))
    .then(() => dialog.busy(false));
}

function rotateccw() {
  rotate(false);
}

function rotatecw() {
  rotate(true);
}

function save() {
  dialog.busy(true);
  const data = {
    url: image.url,
    width: 600,
  };
  const crop = dialog.cropRegion();
  if (crop) {
    data.crop = crop;
  }
  post('editor.image.save', null, data)
    .then(({size, url, orgSize}) => dialog.save(url, size, {alt: '', 'data-ce-max-width': orgSize[0]}))
    .catch((err) => error(err) && new ContentTools.FlashUI('no'))
    .then(() => dialog.busy(false));
}

function unmount(ev) {
}

function cancel(ev) {
}

export default function (_dialog) {
  if (dialog) {
    dialog.removeEventListener('imageuploader.cancelupload', cancelupload);
    dialog.removeEventListener('imageuploader.clear', clear);
    dialog.removeEventListener('imageuploader.fileready', fileready);
    dialog.removeEventListener('imageuploader.rotateccw', rotateccw);
    dialog.removeEventListener('imageuploader.rotatecw', rotatecw);
    dialog.removeEventListener('imageuploader.save', save);
    dialog.removeEventListener('imageuploader.unmount', unmount);
    dialog.removeEventListener('cancel', cancel);
  }

  dialog = _dialog;
  dialog.addEventListener('imageuploader.cancelupload', cancelupload);
  dialog.addEventListener('imageuploader.clear', clear);
  dialog.addEventListener('imageuploader.fileready', fileready);
  dialog.addEventListener('imageuploader.rotateccw', rotateccw);
  dialog.addEventListener('imageuploader.rotatecw', rotatecw);
  dialog.addEventListener('imageuploader.save', save);
  dialog.addEventListener('imageuploader.unmount', unmount);
  dialog.addEventListener('cancel', cancel);
}
