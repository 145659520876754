import {gotoUrl} from '../app';
import {Modal} from 'bootstrap';
import {formatIsoDate, formatIsoDateTime} from '../Utilities';
import {generateUrl, post} from '../Network/Network';
import {getListRoute} from '../DateType';

let disposables = [];

function bindPrevNext(selector, step, month, year) {
  const nav = document.querySelector(selector);
  const onClick = () => {
    let newMonth = parseInt(month.value) + step;
    let newYear = parseInt(year.value);
    if (newMonth === 13) {
      newMonth = 1;
      newYear = newYear + 1;
    }
    if (newMonth === 0) {
      newMonth = 12;
      newYear = newYear - 1;
    }
    gotoUrl('absence_overview', {year: newYear, month: newMonth.toString().padStart(2, '0')});
  };
  nav.addEventListener('click', onClick);
  disposables.push(() => nav.removeEventListener('click', onClick));
}

function bindNavigation() {
  const month = document.querySelector('.js-absence-overview-date-selector select[name="month"]');
  const year = document.querySelector('.js-absence-overview-date-selector select[name="year"]');
  const onChange = () => gotoUrl('absence_overview', {year: year.value, month: month.value.padStart(2, '0')});
  month.addEventListener('change', onChange);
  disposables.push(() => month.removeEventListener('change', onChange));
  year.addEventListener('change', onChange);
  disposables.push(() => year.removeEventListener('change', onChange));

  bindPrevNext('.js-absence-overview-date-selector .js-prev', -1, month, year);
  bindPrevNext('.js-absence-overview-date-selector .js-next', 1, month, year);
}

function setDateSelects(selects, isoDate) {
  const [year, month, day] = isoDate.split('-');
  selects.year.value = parseInt(year);
  selects.month.value = parseInt(month);
  selects.day.value = parseInt(day);
}

function setList(container, items, render) {
  container.style.display = items.length ? '' : 'none';
  const ul = container.querySelector('ul');
  ul.innerHTML = '';
  items.forEach((item) => {
    const li = document.createElement('li');
    li.innerHTML = render(item);
    ul.appendChild(li);
  });
}

function bindNewAbsence(modal) {
  const body = modal.querySelector('.absence-overview-modal-newAbsence');
  if (!body) {
    return () => {
    };
  }

  const from = {
    day: body.querySelector('#from_on_day'),
    month: body.querySelector('#from_on_month'),
    year: body.querySelector('#from_on_year'),
  };

  const to = {
    day: body.querySelector('#to_on_day'),
    month: body.querySelector('#to_on_month'),
    year: body.querySelector('#to_on_year'),
  };

  const comment = body.querySelector('textarea');
  const button = body.querySelector('button');

  let newAbsencesVisible = false;
  const form = body.querySelector('.absence-overview-modal-newAbsence-form');
  const toggleNewAbsences = (visible) => {
    if (typeof visible === 'boolean') {
      newAbsencesVisible = visible;
    } else {
      newAbsencesVisible = !newAbsencesVisible;
    }
    form.style.display = newAbsencesVisible ? '' : 'none';
  };
  const headline = body.querySelector('.absence-overview-modal-newAbsence-headline');
  headline.addEventListener('click', toggleNewAbsences);
  disposables.push(() => headline.removeEventListener('click', toggleNewAbsences));

  let selectedUserId;
  let isSubmitting = false;
  const onSubmit = async () => {
    if (isSubmitting) {
      return;
    }
    isSubmitting = true;
    const formData = new FormData();
    formData.set('from_on_day', from.day.value);
    formData.set('from_on_month', from.month.value);
    formData.set('from_on_year', from.year.value);
    formData.set('to_on_day', to.day.value);
    formData.set('to_on_month', to.month.value);
    formData.set('to_on_year', to.year.value);
    formData.set('comment', comment.value);
    formData.set('userId', selectedUserId);
    await post('absence_add', {}, formData);
    window.location.reload();
  };
  button.addEventListener('click', onSubmit);
  disposables.push(() => button.removeEventListener('click', onSubmit));

  return (startIsoDate, isoDate, userId, absences, dates) => {
    selectedUserId = userId;
    toggleNewAbsences(isoDate !== startIsoDate || !absences.length && !dates.length);
    setDateSelects(from, startIsoDate);
    setDateSelects(to, isoDate);
  };
}

function bindPopup(data) {
  const modal = document.querySelector('.js-page-absence-overview-modal');
  const modalUsername = modal.querySelector('.js-page-absence-overview-modal-username');
  const modalDate = modal.querySelector('.js-page-absence-overview-modal-date');

  const newAbsence = bindNewAbsence(modal);

  const instance = new Modal(modal);
  disposables.push(() => {
    instance.hide();
    instance.dispose();
  });
  let isVisible = false;

  let lastDownTd = null;
  const onMouseDown = (event) => {
    if (event.button === 0 && event.target.matches('.absence-overview-cell-dateentry')) {
      lastDownTd = event.target;
      lastDownTd.classList.add('absence-overview-cell-dateentry--selection-start');
    }
  };
  const onMouseUp = (event) => {
    if (isVisible) {
      return;
    }
    const startTd = lastDownTd;
    if (lastDownTd) {
      lastDownTd.classList.remove('absence-overview-cell-dateentry--selection-start');
      lastDownTd = null;
    }
    if (event.button === 0 && event.target.matches('.absence-overview-cell-dateentry')) {
      const userId = parseInt(event.target.getAttribute('data-user-id'));
      const isoDate = event.target.getAttribute('data-date');
      let startUserId = null;
      let startIsoDate = isoDate;
      if (startTd) {
        startUserId = parseInt(startTd.getAttribute('data-user-id'));
        if (startUserId === userId) {
          startIsoDate = startTd.getAttribute('data-date');
        }
      }

      modalUsername.innerHTML = data.users[userId].username;
      modalDate.innerHTML = formatIsoDate(isoDate);

      const absences = (data.absencesPerUserAndDate[userId]?.[isoDate] || []).map((id) => data.absences[id]);
      setList(
        modal.querySelector('.absence-overview-modal-absences'),
        absences,
        ({start, end, comment}) => `${formatIsoDate(start)}-${formatIsoDate(end)}: ${comment}`,
      );
      modal.querySelector('.absence-overview-modal-absences-edit')
           ?.setAttribute('href', generateUrl('absence_list', {userId}));

      const dates = data.datesPerIsoDate[isoDate] || [];
      setList(
        modal.querySelector('.absence-overview-modal-dates'),
        dates,
        ({id, start, subject, type}) => {
          const href = generateUrl(getListRoute(type), {year: start.slice(0, 4)}, `t-${id}`);
          const goTo = `<a class="absence-overview-modal-date-link" href="${href}"><i class="fa-solid fa-arrow-up-right-from-square"></i></a>`;
          const planned = type === 'planned' ? ' (geplant)' : '';
          return `${formatIsoDateTime(start)}${planned}: ${subject} ${goTo}`;
        },
      );

      newAbsence(startIsoDate, isoDate, userId, absences, dates);

      isVisible = true;
      instance.show();
    }
  };

  const onHidden = () => {
    setTimeout(() => {
      isVisible = false;
    }, 250);
  };
  modal.addEventListener('hidden.bs.modal', onHidden);
  document.addEventListener('mousedown', onMouseDown);
  document.addEventListener('mouseup', onMouseUp);
  disposables.push(() => {
    modal.removeEventListener('hidden.bs.modal', onHidden);
    document.removeEventListener('mousedown', onMouseDown);
    document.removeEventListener('mouseup', onMouseUp);
  });
}

function buildColumnHoverCss() {
  const styles = [];
  [...document.querySelectorAll('.absence-overview-cell-dayname')].forEach((th) => {
    const isoDate = th.getAttribute('data-date');
    /*language=scss*/
    styles.push(`
      .absence-overview-table:has(.absence-overview-cell-dateentry[data-date="${isoDate}"]:hover) th.absence-overview-cell-dayname[data-date="${isoDate}"],
      .absence-overview-table:has(.absence-overview-cell-dateentry[data-date="${isoDate}"]:hover) th.absence-overview-cell-date[data-date="${isoDate}"] {
        background: #015196;
        color: white;
      }
    `);
  });
  const sheet = document.createElement('style');
  sheet.innerHTML = styles.join('\n');
  document.body.appendChild(sheet);
  disposables.push(() => {
    document.body.removeChild(sheet);
  });
}

export function init() {
  destroy();
  const dataNode = document.querySelector('.js-page-absence-overview');
  if (dataNode) {
    const data = JSON.parse(dataNode.getAttribute('data-export'));
    bindNavigation();
    bindPopup(data);
    buildColumnHoverCss();
  }
}

export function destroy() {
  disposables.forEach((fn) => fn());
  disposables = [];
}
