import * as Editor from '../Editor/Editor';
import {del, post} from '../Network/Network';
import * as Dialogs from '../Dialogs';

let disposables = [];

/**
 * Removes a post
 * @param {Event} event
 */
function remove(event) {
  const entry = event.currentTarget.parentElement;
  Dialogs.confirm('Möchtest du diesen Beitrag wirklich löschen?').then((confirmed) => {
    if (confirmed) {
      const id = parseInt(entry.getAttribute('data-id'));
      del('editor.news.delete_post', {id}).then(() => {
        entry.parentElement.removeChild(entry);
        init();
      });
    }
  });
}

/**
 * Binds the remove buttons
 */
function bindRemove() {
  [...document.querySelectorAll('.page-news--post-removeButton')].forEach((button) => {
    button.addEventListener('click', remove);
    disposables.push(() => button.removeEventListener('click', remove));
  });
}

/**
 * Adds a new free post
 */
function addPost() {
  post('editor.news.create_post', null, null, {headers: {'Accept': 'text/html'}}).then((html) => {
    document.querySelector('.page-news-add').insertAdjacentHTML('afterend', html);
    init(true);
    Editor.selectForEditing(document.querySelector('.content-area').getAttribute('data-id'));
  });
}

export function init(newPost = false) {
  destroy();
  const addButton = document.querySelector('.page-news-add-button');
  if (addButton) {
    addButton.addEventListener('click', addPost);
    disposables.push(() => addButton.removeEventListener('click', addPost));
    if (newPost) {
      Editor.init();
    }
    bindRemove();
  }
}

export function destroy() {
  disposables.forEach((fn) => fn());
  disposables = [];
}
