import {del, patch, post} from '../Network/Network';
import {initDropArea} from '../DragAndDrop/FileDropUtilities';
import * as Dialogs from '../Dialogs';

let disposables = [];

/**
 * @returns {Number}
 */
function getAlbumId() {
  const container = document.querySelector('.js-gallery-album-edit');
  return parseInt(container.getAttribute('data-id'), 10);
}

/**
 * @param {HTMLElement} picDom
 * @param {Object} data
 * @returns {Promise}
 */
function patchPicture(picDom, data) {
  const pictureId = parseInt(picDom.getAttribute('data-id'), 10);
  return post('album_picture_patch', {pictureId}, data);
}

/**
 * @param {HTMLElement} picDom
 * @param {number} degrees
 * @returns {Promise}
 */
async function rotatePicture(picDom, degrees) {
  const classList = picDom.querySelector('.gallery-edit-picture-rotate').classList;
  if (!classList.contains('is-disabled')) {
    const overlay = picDom.querySelector('.gallery-edit-picture-preview-overlay');
    overlay.style.display = 'block';
    classList.add('is-disabled');
    const pictureId = parseInt(picDom.getAttribute('data-id'), 10);
    try {
      const {srcSet} = await patch('album_picture_rotate', {pictureId, degrees});
      picDom.querySelector('.gallery-edit-picture-preview-img').setAttribute('srcset', srcSet);
    } finally {
      classList.remove('is-disabled');
      overlay.style.display = '';
    }
  }
}

/**
 * @param {HTMLElement} picDom
 * @param {Object} data
 * @returns {Promise}
 */
function patchAlbum(data) {
  return post('album_patch', {albumId: getAlbumId()}, data);
}

function allPicturesInternal(internal) {
  return patchAlbum({allPicturesInternal: internal}).then(() => {
    [...document.querySelectorAll('.js-is-internal')].forEach((input) => input.checked = internal);
  });
}

function makeAllInternal() {
  return allPicturesInternal(true);
}

function makeAllPublic() {
  return allPicturesInternal(false);
}

function onTakenOnChanged(picDom) {
  const vals = [...picDom.querySelectorAll('select')].map((select) => parseInt(select.value, 10));
  if (vals.length !== 6) {
    throw new Error('Invalid number of selects');
  }
  const date = new Date(vals[2], vals[1] - 1, vals[0], vals[3], vals[4], vals[5]);
  const unix = parseInt(date.getTime() / 1000, 10);
  patchPicture(picDom, {takenOn: unix});
}

function removePicture(picDom) {
  const pictureId = parseInt(picDom.getAttribute('data-id'), 10);
  const name = picDom.getAttribute('data-file-name');
  Dialogs.confirm(`Möchtest du wirklich '${name}' löschen?`).then((confirmed) => {
    if (confirmed) {
      del('album_picture_delete', {albumId: getAlbumId(), pictureId})
        .then(() => picDom.parentElement.parentElement.removeChild(picDom.parentElement));
    }
  });
}

function makePreview(picDom, makePreviewDom) {
  const list = makePreviewDom.classList;
  if (list.contains('isnot-preview')) {
    patchPicture(picDom, {isPreviewPicture: true}).then(() => {
      [...document.querySelectorAll('.gallery-edit-makePreviewPicture')].forEach((dom) => {
        dom.classList.remove('is-preview');
        dom.classList.add('isnot-preview');
      });
      list.remove('isnot-preview');
      list.add('is-preview');
      document.getElementById('gallery-preview-picture-name').innerHTML = picDom.getAttribute('data-file-name');
    });
  }
}

export function init() {
  destroy();
  const container = document.querySelector('.js-gallery-album-edit');
  if (container) {
    if (document.querySelector('.fileUpload-dropzone')) {
      disposables.push(initDropArea(
        'album_picture_add',
        {albumId: getAlbumId()},
        init, //onStackFinished
      ));
    }

    const makeAllInternalLink = document.querySelector('.gallery-make-all-intern');
    makeAllInternalLink.addEventListener('click', makeAllInternal);
    disposables.push(() => makeAllInternalLink.removeEventListener('click', makeAllInternal));

    const makeAllPublicLink = document.querySelector('.gallery-make-all-public');
    makeAllPublicLink.addEventListener('click', makeAllPublic);
    disposables.push(() => makeAllPublicLink.removeEventListener('click', makeAllPublic));

    [...document.querySelectorAll('.gallery-edit-picture')].forEach((picDom) => {
      [...picDom.querySelectorAll('select')].forEach((select) => {
        const handler = onTakenOnChanged.bind(null, picDom);
        select.addEventListener('change', handler);
        disposables.push(() => select.removeEventListener('change', handler));
      });
      {
        const desc = picDom.querySelector('.js-picture-rotate-left');
        const handler = () => rotatePicture(picDom, -90);
        desc.addEventListener('click', handler);
        disposables.push(() => desc.removeEventListener('click', handler));
      }
      {
        const desc = picDom.querySelector('.js-picture-rotate-right');
        const handler = () => rotatePicture(picDom, 90);
        desc.addEventListener('click', handler);
        disposables.push(() => desc.removeEventListener('click', handler));
      }
      {
        const desc = picDom.querySelector('.js-description');
        const handler = () => patchPicture(picDom, {description: desc.value});
        desc.addEventListener('change', handler);
        disposables.push(() => desc.removeEventListener('change', handler));
      }
      {
        const chkbox = picDom.querySelector('.js-is-internal');
        const handler = () => patchPicture(picDom, {isInternal: chkbox.checked});
        chkbox.addEventListener('change', handler);
        disposables.push(() => chkbox.removeEventListener('change', handler));
      }
      {
        const removeBtn = picDom.querySelector('.js-delete-picture');
        const handler = removePicture.bind(null, picDom);
        removeBtn.addEventListener('click', handler);
        disposables.push(() => removeBtn.removeEventListener('click', handler));
      }
      {
        const makePreviewDom = picDom.querySelector('.gallery-edit-makePreviewPicture');
        const handler = makePreview.bind(null, picDom, makePreviewDom);
        makePreviewDom.addEventListener('click', handler);
        disposables.push(() => makePreviewDom.removeEventListener('click', handler));
      }
    });
  }
}

export function destroy() {
  disposables.forEach((fn) => fn());
  disposables = [];
}
