import * as Login from './Login';
import * as MusicPlayer from './MusicPlayer';
import * as Guestbook from './Pages/Guestbook';
import * as Editor from './Editor/Editor';
import * as GoogleMaps from './GoogleMaps';
import * as Pikaday from './Pikaday';
import * as News from './Pages/News';
import * as Dates from './Pages/Dates';
import * as Gallery from './Pages/Gallery';
import * as GalleryEdit from './Pages/GalleryEdit';
import * as PlaylistEdit from './Pages/PlaylistEdit';
import * as Absence from './Pages/Absence';
import * as AbsenceOverview from './Pages/AbsenceOverview';
import * as Calendar from './Pages/Calendar';
import * as Files from './Pages/Files';
import * as ResetPassword from './Pages/ResetPassword';
import * as ResetPasswordRequest from './Pages/ResetPasswordRequest';
import * as Logs from './Pages/Logs';
import * as StickyNavigation from './StickyNavigation';
import * as UnprotectEmails from './UnprotectEmails';
import * as Navigation from './Navigation';
import * as FormAutoSave from './FormAutoSave';
import * as FormAjaxSave from './FormAjaxSave';

export default [
  Login,
  Guestbook,
  Editor,
  News,
  Dates,
  GoogleMaps,
  Gallery,
  GalleryEdit,
  PlaylistEdit,
  Pikaday,
  Absence,
  AbsenceOverview,
  Calendar,
  Files,
  ResetPassword,
  ResetPasswordRequest,
  Logs,
  MusicPlayer,
  StickyNavigation,
  UnprotectEmails,
  Navigation,
  FormAutoSave,
  FormAjaxSave,
];
