function getForm() {
  return document.getElementById('reset-password-form');
}

function getButton() {
  return document.getElementById('reset-password-submit-button');
}

function onSubmit(e) {
  const btn = getButton();
  const pw1 = document.getElementById('password').value.trim();
  const pw2 = document.getElementById('password2').value.trim();
  if (pw1 !== pw2 || pw1.length < 8) {
    document.getElementById('reset-password-error').style.display = '';
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
  } else if (btn.isSubmitting) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
  } else {
    btn.isSubmitting = true;
  }
}

export function init() {
  destroy();
  const form = getForm();
  form && form.addEventListener('submit', onSubmit);
}

export function destroy() {
  const form = getForm();
  form && form.removeEventListener('submit', onSubmit);
}
