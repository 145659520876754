import {post} from './Network/Network';
import {changeHtml} from './app';

let disposables = [];

/**
 * @param {HTMLElement} form
 * @return {Promise}
 */
export function submitForm(form) {
  const data = new FormData(form);
  return post(form.action, {}, data, {headers: {'Accept': 'text/html'}, returnResponse: true}).then((response) => {
    response.text().then((html) => changeHtml(html, response.url));
  });
}

/**
 * Called upon form submit
 *
 * @param {Event} event
 */
function onFormSubmit(event) {
  if (!event.currentTarget.getAttribute('target')) {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
    submitForm(event.currentTarget);
  }
}

export function init() {
  destroy();

  const site = window.location.origin;
  [...document.querySelectorAll('form')].forEach((form) => {
    if (form.action.indexOf(site) === 0 && !form.classList.contains('js-no-ajax-load')) {
      form.addEventListener('submit', onFormSubmit);
      disposables.push(() => form.removeEventListener('submit', onFormSubmit));
    }
  });
}

export function destroy() {
  disposables.forEach((fn) => fn());
  disposables = [];
}
