import {gotoUrl} from '../app';
import {getLastSelectedEndDayjs, getLastSelectedStartDayjs} from './Calendar';

let disposables = [];

export function init() {
  destroy();
  if (document.querySelector('.js-page-absence-list')) {
    const lastStartDayjs = getLastSelectedStartDayjs();
    let lastEndDayjs = getLastSelectedEndDayjs();
    if (lastStartDayjs && lastEndDayjs) {
      lastEndDayjs = lastEndDayjs.subtract(1, 'd'); //fullcalendar uses exclusive end dates
      const from = [...document.querySelectorAll('.js-absence-from select')];
      const to = [...document.querySelectorAll('.js-absence-to select')];
      from[0].value = lastStartDayjs.date();
      from[1].value = lastStartDayjs.month() + 1;
      from[2].value = lastStartDayjs.year();
      to[0].value = lastEndDayjs.date();
      to[1].value = lastEndDayjs.month() + 1;
      to[2].value = lastEndDayjs.year();
    }

    const selectMember = document.querySelector('.js-absence-change-member');
    if (selectMember) {
      const onChange = () => gotoUrl(selectMember.value);
      selectMember.addEventListener('change', onChange);
      disposables.push(() => selectMember.removeEventListener('change', onChange));
    }
  }
}

export function destroy() {
  disposables.forEach((fn) => fn());
  disposables = [];
}
