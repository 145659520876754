'use strict';

/**
 * Hides the dragged target
 *
 * This must be called in a dragstart event
 *
 * @param {DragEvent} event
 */
export function hideDraggedTarget(event) {
  if (event.dataTransfer.setDragImage) {
    //Disable the drag image by giving an empty new <div>:
    event.dataTransfer.setDragImage(document.createElement('div'), 0, 0);
  } else {
    //Fallback for IE/Edge not supporting setDragImage (this causes a brief flicker):
    const handleStyle = event.target.style;
    handleStyle.opacity = 0;
    setTimeout(() => handleStyle.opacity = '', 0);
  }
}

/**
 * Stops the event
 * @param {Event} event
 */
export function stopEvent(event) {
  event.stopPropagation();
  event.stopImmediatePropagation();
  event.preventDefault();
  return false;
}

/**
 * Call this in a dragover event to move the draggedElement to the draggedOverElement position
 *
 * This assumes that draggedElement and draggedOverElement are siblings!
 *
 * @param {HTMLElement} draggedOverElement the item that is currently being dragged over (event.target of dragover)
 * @param {HTMLElement} draggedElement the item being dragged
 */
export function moveDraggedItemToTarget(draggedOverElement, draggedElement) {
  if (draggedOverElement && draggedElement && draggedOverElement !== draggedElement) {
    const siblings = [...draggedOverElement.parentElement.children]; //converts NodeList to Array
    const targetIndex = siblings.indexOf(draggedOverElement);
    const draggedIndex = siblings.indexOf(draggedElement);

    if (draggedIndex < targetIndex) {
      draggedOverElement.parentNode.insertBefore(draggedElement, draggedOverElement.nextElementSibling);
    } else {
      draggedOverElement.parentNode.insertBefore(draggedElement, draggedOverElement);
    }
  }
}

/**
 * Stops the drag over event and prevents the cursor from changing
 * @param {Event} event
 */
export function stopDragOver(event) {
  const origionalEvent = event.originalEvent || event;
  origionalEvent.dataTransfer.dropEffect = 'none';
  return stopEvent(origionalEvent);
}

/**
 * Finds a parent node with the right identifier
 */
export function findParent(target, identifier) {
  let context = target;
  while (context.tagName !== identifier) {
    context = context.parentNode;
  }
  return context;
}


