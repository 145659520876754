/**
 * @param {Function} onProgress
 * @return {Function}
 */
function getOnXhrProgress(onProgress) {
  const startTime = Date.now();
  /**
   * @param {XMLHttpRequestProgressEvent} event
   */
  return function (event) {
    // if the file upload length is known, then notify
    if (event.lengthComputable) {
      const elapsed = (event.timeStamp - startTime) / 1000;
      const remainingBytes = event.total - event.loaded;
      const progress = event.loaded / event.total;
      const speed = event.loaded / elapsed;
      onProgress({
        'bytesPerSecond': speed,
        'elapsedSeconds': elapsed,
        'remainingBytes': remainingBytes,
        'remainingSeconds': remainingBytes / speed,
        'sentBytes': event.loaded,
        'totalBytes': event.total,
        'progress': progress,
        'percent': progress * 100,
      });
    }
  };
}

/**
 * @param {String} url
 * @param {FileList} files
 * @param {Function} onProgress
 * @return {Promise}
 */
function uploadXhr(url, files, onProgress) {
  return new Promise((resolve, reject) => {
    const formData = new FormData();

    // iterate over the files dragged on to the browser
    for (let i = 0; i < files.length; i++) {
      // add each file to FormData object
      formData.append('file-' + i, files[i]);

      const folder = files[i].relativePath || '';
      formData.append('relativePath-' + i, folder);

      const extractArchive = Boolean(files[i].extractArchive);
      formData.append('extractArchive-' + i, +extractArchive);

      formData.append('lastModified-' + i, parseInt(files[i].lastModified / 1000, 10));
    }

    const xhr = new XMLHttpRequest();

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if ([200, 201].indexOf(xhr.status) > -1) {
          resolve(JSON.parse(xhr.responseText));
        } else {
          reject(new Response(xhr.response || xhr.responseText, {
            status: xhr.status,
            statusText: xhr.statusText,
          }));
        }
      }
    };

    if (typeof onProgress === 'function') {
      xhr.upload.onprogress = getOnXhrProgress(onProgress);
    }

    xhr.open('POST', url);
    xhr.send(formData);
  });
}

/**
 * Validate the file number
 */
function validateNumberOfFiles(instance, count) {
  if (!count) {
    throw new Error('No files to upload');
  }

  if (count > instance.maxFileUploads) {
    throw new Error('The files are exceeding the max_file_uploads server setting');
  }
}

/**
 * Checks that the given files can be uploaded
 * @param {FileUploader} instance
 * @param {FileList|Files[]} files
 */
function validateFiles(instance, files) {
  const count = files.length;

  validateNumberOfFiles(instance, count);

  let totalSize = 0;
  let largestSize = 0;
  for (let i = 0; i < count; i++) {
    const fSize = files[i].size;
    totalSize += fSize;
    if (fSize > largestSize) {
      largestSize = fSize;
    }
  }

  if (totalSize > instance.postMaxSize) {
    throw new Error('The files are exceeding the post_max_size server setting');
  }

  if (largestSize > instance.uploadMaxFilesize) {
    throw new Error('The files are exceeding the upload_max_filesize server setting');
  }
}

/**
 * Uploads the files to the server
 * @param {FileUploader} instance
 * @param {FileList|Files[]} files
 * @param {Function} onProgress called during upload with the percentage of upload progress
 * @return {Promise.<Object[]>} resolves into an array of objects
 */
function upload(instance, files, onProgress) {
  try {
    validateFiles(instance, files);
  } catch (e) {
    return Promise.reject(e.message);
  }

  //use XHR for now until Fetch supports a progress
  // see https://www.accelebrate.com/blog/web-page-file-uploads-ajax-part-2-of-3/
  return uploadXhr(instance.saveUrl, files, onProgress);

  //possibly use websockets later on:
  // https://www.accelebrate.com/blog/file-uploads-web-sockets-part-3-of-3/
}

export default class FileUploader {
  /**
   * @param {Number} maxFileUploads
   * @param {Number} postMaxSize
   * @param {Number} uploadMaxFilesize
   * @param {String} saveUrl
   */
  constructor(maxFileUploads, postMaxSize, uploadMaxFilesize, saveUrl) {
    this.maxFileUploads = maxFileUploads;
    this.postMaxSize = postMaxSize;
    this.uploadMaxFilesize = uploadMaxFilesize;
    this.saveUrl = saveUrl;
  }

  /**
   * Uploads the files to the server
   * @param {FileUploader} instance
   * @param {FileList|Files[]} files
   * @param {Function} onProgress called during upload with the percentage of upload progress
   * @return {Promise.<Object[]>} resolves into an array of objects
   */
  upload(files, onProgress) {
    return upload(this, files, onProgress);
  }
}
