import {get} from '../Network/Network';
import {gotoUrl} from '../app';
import * as Storage from '../Storage';
import dayjs from 'dayjs';
import 'dayjs/locale/de';

dayjs.locale('de');

let disposables = [];
const eventLoadPromises = new Map();
let lastSelectedStartDate = null;
let lastSelectedEndDate = null;

/**
 * Clears the dates cache
 */
function clearCache() {
  eventLoadPromises.clear();
}

const defaults = {
  get date() {
    return Storage.getSession('Calendar-defaults-date');
  },
  set date(val) {
    Storage.setSession('Calendar-defaults-date', val);
  },
  get view() {
    return Storage.getSession('Calendar-defaults-view', 'dayGridMonth');
  },
  set view(val) {
    Storage.setSession('Calendar-defaults-view', val);
  },
};

/**
 * @param {Number} year
 * @return {Promise}
 */
function loadYear(year) {
  if (!eventLoadPromises.has(year)) {
    eventLoadPromises.set(year, get('calendar_events', {year}));
  }
  return eventLoadPromises.get(year);
}

/**
 * @param {{start: Date; end: Date}} fetchInfo
 * @param {Function} success
 */
function events({start, end}, success) {
  const startYear = start.getUTCFullYear();
  const endYear = end.getUTCFullYear();
  const startProm = loadYear(startYear);
  const endProm = loadYear(endYear);
  if (startYear === endYear) {
    startProm.then(success);
  } else {
    Promise.all([startProm, endProm]).then(([startEvs, endEvs]) => success([...startEvs, ...endEvs]));
  }
}

/**
 * @param {{title: String, allDay: boolean, start: moment, end: moment|null}} event
 * @return {Element}
 */
function createEventToolTip(event) {
  const tooltip = document.createElement('div');
  tooltip.classList.add('calendar-event-tooltip');

  if (event.extendedProps.isCanceled || event.extendedProps.isPlanned) {
    const typeHeader = document.createElement('div');
    typeHeader.classList.add('calendar-event-tooltip-header');
    typeHeader.classList.add('calendar-event-tooltip-header--type');
    typeHeader.innerHTML = event.extendedProps.isCanceled ? 'ABGESAGT' : 'IN PLANUNG';
    tooltip.appendChild(typeHeader);
  }

  const header = document.createElement('div');
  header.classList.add('calendar-event-tooltip-header');
  header.innerHTML = event.title;
  tooltip.appendChild(header);

  const time = document.createElement('div');
  time.classList.add('calendar-event-tooltip-time');
  const startDayjs = dayjs(event.start);
  if (event.allDay) {
    const realEndDayjs = event.end ? dayjs(event.end).subtract(1, 'd') : null;
    if (realEndDayjs && startDayjs.isSame(realEndDayjs, 'day')) {
      time.innerHTML = startDayjs.format('dddd, DD. MMMM YYYY');
    } else if (realEndDayjs) {
      time.innerHTML = startDayjs.format('DD.MM.YYYY') + ' bis ' + realEndDayjs.format('DD.MM.YYYY');
    } else {
      time.innerHTML = startDayjs.format('DD.MM.YYYY');
    }
  } else {
    time.innerHTML = startDayjs.format('dddd, DD. MMMM YYYY, HH:mm');
  }
  tooltip.appendChild(time);

  const text = document.createElement('div');
  text.classList.add('calendar-event-tooltip-text');
  text.innerHTML = event.extendedProps.description;
  tooltip.appendChild(text);

  return tooltip;
}

/**
 * @returns {dayjs.Dayjs|null}
 */
export function getLastSelectedStartDayjs() {
  return lastSelectedStartDate ? dayjs(lastSelectedStartDate) : null;
}

/**
 * @returns {dayjs.Dayjs|null}
 */
export function getLastSelectedEndDayjs() {
  return lastSelectedEndDate ? dayjs(lastSelectedEndDate) : null;
}

export function init() {
  destroy();
  const cal = document.querySelector('.js-fullcalendar');
  if (cal) {
    import('../loaders/fullcalendar').then(({default: {Calendar, daygrid, deLocale, interaction}}) => {
      const instance = new Calendar(cal, {
        locale: deLocale,
        firstDay: 1,
        plugins: [daygrid, interaction],
        initialDate: defaults.date,
        initialView: defaults.view,
        events,
        viewDidMount({view}) {
          defaults.date = new Date((view.currentStart.valueOf() + view.currentEnd.valueOf()) / 2); //just somewher in
                                                                                                   // the middle ...
          defaults.view = view.type;
        },
        select({start, end}) {
          lastSelectedStartDate = start;
          lastSelectedEndDate = end;
        },
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,dayGridWeek',
        },
        navLinks: true, // can click day/week names to navigate templates
        navLinkDayClick(date) {
          lastSelectedStartDate = date;
          lastSelectedEndDate = null;
          gotoUrl('date_add');
        },
        eventClassNames: ({event}) => {
          let css = `calendar-event--${event.extendedProps.type}`;
          if (event.extendedProps.isPlanned) {
            css += ` calendar-event--isPlanned`;
          }
          if (event.extendedProps.isCanceled) {
            css += ` calendar-event--isCanceled`;
          }
          return css;
        },
        eventDidMount({event, el}) {
          el.appendChild(createEventToolTip(event));
        },
        selectable: true,
      });
      instance.render();
      disposables.push(() => instance.destroy());
    });
  }

  [...document.querySelectorAll('.js-calendar-influencing-form')].forEach((form) => {
    form.addEventListener('submit', clearCache);
    disposables.push(() => form.removeEventListener('submit', clearCache));
  });
}

export function destroy() {
  disposables.forEach((fn) => fn());
  disposables = [];
}
