export function getListRoute(type) {
  switch (type) {
    case 'planned':
      return 'plannedDates';
    case 'rehearsal':
      return 'rehearsals';
    default:
      return 'dates';
  }
}
