import JSONFormatter from 'json-formatter-js';

let disposables = [];

const selectedClass = 'is-selected';

/**
 * @param {HTMLElement} div
 * @return {string}
 */
function buildJsonDisplay(div) {
  const json = JSON.parse(div.getAttribute('data-details'));
  const formatter = new JSONFormatter(json);
  div.appendChild(formatter.render());
}

/**
 * @param {Event} event
 */
function toggle(event) {
  const parent = event.currentTarget.parentElement;
  const parentList = parent.classList;
  if (parentList.contains(selectedClass)) {
    parentList.remove(selectedClass);
  } else {
    [...document.querySelectorAll('.log-entry-row')].forEach((row) => {
      row.parentElement.classList.remove(selectedClass);
    });
    const details = parent.querySelector('.log-entry-details');
    if (!details.innerHTML) {
      buildJsonDisplay(details);
    }
    parentList.add(selectedClass);
  }
}

export function init() {
  destroy();
  [...document.querySelectorAll('.log-entry-row')].forEach((row) => {
    row.addEventListener('click', toggle);
    disposables.push(() => row.removeEventListener('click', toggle));
  });
}

export function destroy() {
  disposables.forEach((fn) => fn());
  disposables = [];
}
