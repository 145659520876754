let disposables = [];
let openAlbum = null;
let selectedPictureId = null;
let albumVisible = false;

/**
 * @param {Object} obj
 * @returns {string}
 */
function setHash(obj) {
  const hash = obj ? btoa(JSON.stringify(obj)) : '';
  window.location.hash = hash;
  // const loc = window.location;
  // const url = `${loc.origin}${loc.pathname}#${hash}`;
  // history.pushState({}, '', url);
  // window.location.href = url;
}

/**
 * @param {String} hash
 * @returns {Object}
 */
function hashToObject(hash) {
  let obj = {};
  hash = hash.replace(/^\#/, '');
  if (hash) {
    try {
      obj = JSON.parse(atob(hash));
    } catch (e) {
    }
  }
  return obj;
}

/**
 * @param {HTMLElement} albumDom
 * @returns {Number}
 */
function getId(albumDom) {
  return parseInt(albumDom.getAttribute('data-id'), 10);
}

function getSlide() {
  return document.querySelector('.gallery-slideshow');
}

/**
 * @param {Number} albumId
 * @returns {Promise.<Object>}
 */
function loadAlbum(albumId) {
  const dom = document.getElementById(`gallery-album--${albumId}`);
  const json = JSON.parse(dom.getAttribute('data-json'));
  return Promise.resolve(json);
}

/**
 * @param {Number} pictureId
 */
function showPicture(pictureId) {
  const picture = openAlbum.pictures.find((pic) => pic.id === pictureId) || openAlbum.pictures[0];

  const navi = document.querySelector('.gallery-slideshow-inner-nav');

  [...navi.querySelectorAll('.gallery-slideshow-nav-item')].forEach((item) => {
    item.classList.remove('gallery-slideshow-nav-item--selected');
    if (+item.getAttribute('data-id') === picture.id) {
      item.classList.add('gallery-slideshow-nav-item--selected');

      const alignItem = () => {
        const naviRect = navi.getBoundingClientRect();
        const itemRect = item.getBoundingClientRect();
        const leftDiff = itemRect.left - naviRect.left;
        const rightDiff = itemRect.right - naviRect.right;
        if (leftDiff < 0) {
          navi.scrollLeft = item.offsetLeft;
        } else if (rightDiff > 0) {
          navi.scrollLeft = navi.scrollLeft + rightDiff;
        }
      };

      if (albumVisible) {
        alignItem();
      } else {
        setTimeout(alignItem, 300);
      }
    }
  });

  const img = document.querySelector('.gallery-slideshow-inner-picture-img');
  img.setAttribute('srcset', picture.srcSet);

  const desc = document.querySelector('.gallery-slideshow-inner-picDescription');
  desc.innerHTML = picture.description;
  desc.style.display = picture.description ? '' : 'none';

  selectedPictureId = picture.id;
}

/**
 * Takes a step
 * @param {Boolean} forward
 */
function step(forward) {
  let nextIndex;
  const length = openAlbum.pictures.length;
  const currentIndex = openAlbum.pictures.findIndex((pic) => pic.id === selectedPictureId);
  if (forward) {
    nextIndex = currentIndex + 1 >= length ? 0 : currentIndex + 1;
  } else {
    nextIndex = currentIndex - 1 < 0 ? length - 1 : currentIndex - 1;
  }
  showPicture(openAlbum.pictures[nextIndex].id);
}

/**
 * @param {Number} albumId
 * @param {Number} pictureId
 */
function onNaviClick(albumId, pictureId) {
  if (selectedPictureId !== pictureId) {
    setHash({albumId, pictureId});
  }
}

/**
 * Renders the navigation for an album
 */
function renderNavigation() {
  const container = document.querySelector('.gallery-slideshow-inner-nav');
  container.innerHTML = '';
  openAlbum.pictures.forEach((pic) => {
    const div = document.createElement('div');
    div.classList.add('gallery-slideshow-nav-item');
    div.setAttribute('data-id', pic.id);
    const img = document.createElement('img');
    img.classList.add('gallery-slideshow-nav-item-img');
    img.setAttribute('srcset', pic.srcSet);
    img.setAttribute('sizes', '6rem');
    img.setAttribute('loading', 'lazy');
    div.appendChild(img);
    div.addEventListener('click', () => onNaviClick(openAlbum.id, pic.id));
    container.appendChild(div);
  });
}

function renderAlbumTexts() {
  [
    ['title', '.gallery-slideshow-inner-albumTitle'],
    ['description', '.gallery-slideshow-inner-albumDescription'],
    ['copyright', '.gallery-slideshow-inner-albumCopyright'],
  ].forEach(([field, selector]) => {
    const dom = document.querySelector(selector);
    const value = openAlbum[field];
    dom.querySelector('span').innerHTML = value;
    dom.style.display = value ? '' : 'none';
  });
}

/**
 * @param {Number} albumId
 * @param {Number} pictureId
 * @returns {Promise}
 */
function showAlbum(albumId, pictureId) {
  let prom;
  if (openAlbum && openAlbum.id === albumId) {
    prom = Promise.resolve();
  } else {
    albumVisible = false;
    prom = loadAlbum(albumId).then((album) => {
      openAlbum = album;
      renderAlbumTexts();
      renderNavigation();
      getSlide().classList.add('is-open');
      setTimeout(() => albumVisible = true, 300);
    });
  }
  return prom.then(() => showPicture(pictureId));
}

/**
 * Closes the album
 */
function closeAlbum() {
  openAlbum = null;
  getSlide().classList.remove('is-open');
}

/**
 * Called when the hash changes
 */
function onPopstate() {
  const obj = hashToObject(window.location.hash);
  if (obj.albumId) {
    showAlbum(obj.albumId, obj.pictureId);
  } else {
    closeAlbum();
  }
}

/**
 * @param {HTMLElement} albumDom
 */
function onAlbumClick(albumDom) {
  setHash({albumId: getId(albumDom)});
}

export function init() {
  const container = document.querySelector('.gallery');
  if (container) {
    history.scrollRestoration = 'manual';

    [...document.querySelectorAll('.gallery-album.has-pictures')].forEach((albumDom) => {
      const innerDom = albumDom.querySelector('.gallery-album-inner');
      const handler = onAlbumClick.bind(null, albumDom);
      innerDom.addEventListener('click', handler);
      disposables.push(() => innerDom.removeEventListener('click', handler));
    });

    {
      const prev = document.querySelector('.gallery-slideshow-inner-picture-nav.is-prev');
      const handler = step.bind(null, false);
      prev.addEventListener('click', handler);
      disposables.push(() => prev.removeEventListener('click', handler));
    }
    {
      const next = document.querySelector('.gallery-slideshow-inner-picture-nav.is-next');
      const handler = step.bind(null, true);
      next.addEventListener('click', handler);
      disposables.push(() => next.removeEventListener('click', handler));
    }

    window.addEventListener('popstate', onPopstate);
    disposables.push(() => window.removeEventListener('popstate', onPopstate));
    onPopstate();
  }
}

export function destroy() {
  disposables.forEach((fn) => fn());
  disposables = [];
  history.scrollRestoration = 'auto';
}
