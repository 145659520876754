import {error} from '../Logger';
import {del, post} from '../Network/Network';
import ImageUploader from './ImageUploader';

let autoSaveTimer;
let editor = null;
let contentRestored = false;
let initializing = false;

/**
 * @param {String[]} idents
 * @returns {*}
 */
function collectImages(idents) {
  const regions = editor.regions();
  return idents.reduce((list, ident) => {
    regions[ident].descendants().forEach((desc) => {
      if (desc.type() === 'Image') {
        list[desc.attr('src')] = desc.size()[0];
      }
    });
    return list;
  }, {});
}

/**
 * @param {{detail: function}} event
 */
function onSaved(event) {
  const details = event.detail();
  const regions = details.regions;
  const autoSave = Boolean(details.passive);
  const idents = Object.keys(regions);
  const allRegionIdents = Object.keys(editor.regions());
  if (idents.length || !autoSave) {
    editor.busy(true);
    const data = {
      modifiedRegions: regions,
      autoSave,
      allRegionIdents,
    };
    if (!autoSave) {
      data.images = collectImages(idents);
    }
    post('editor.save', null, data).then(() => {
      if (!autoSave) {
        idents.forEach(
          (ident) => document.querySelector(`.content-area[data-id="${ident}"]`).removeAttribute('data-autosaved'));
      }
    })
                                   .catch((err) => error(err))
                                   .then(() => editor.busy(false));
  }
}

/**
 * @returns {[Element]}
 */
function getAutoSaved() {
  return [...document.querySelectorAll('.content-area[data-autosaved]')];
}

/**
 * Clicks the edit button because the programatic call to edit does not change the buttons
 */
function startEditing() {
  const button = document.querySelector('.ct-ignition__button--edit');
  if (button && button.parentElement.classList.contains('ct-ignition--ready')) {
    button.click();
  }
}

/**
 * Restores the auto-saved content and starts the editing
 */
function restoreAutosaved() {
  contentRestored = true;
  startEditing();
  document.querySelector('.editor-autosaved').style.display = '';

  getAutoSaved().forEach((elem) => {
    const region = editor.regions()[elem.getAttribute('data-id')];
    editor._emptyRegionsAllowed = true;
    region.setContent(elem.getAttribute('data-autosaved'));
    editor._emptyRegionsAllowed = false;

    //Because the setContent causes an empty <p> tag to be added as first element, we have to focus all elements until
    // we find the first non-empty one. The blur following the focus will cause the empty <p> to be detached again:
    region.children.some((child) => {
      child.focus();
      return !child._domElement.classList.contains('ce-element--empty');
    });
  });
}

/**
 * @param {String} dataId
 */
export function selectForEditing(dataId) {
  requireIniitalized().then(() => {
    startEditing();
    const region = editor.regions()[dataId];
    if (region) {
      region.children[0].focus();
    }
  });
}

/**
 * Discards the auto-saved contents
 */
function discardAutosaved() {
  document.querySelector('.editor-autosaved').style.display = '';
  const idents = getAutoSaved().map((elem) => {
    elem.removeAttribute('data-autosaved');
    return elem.getAttribute('data-id');
  });
  del('editor.discard', null, {idents});
}

/**
 * Handles the auto saved content
 */
function handleAutosaved() {
  if (getAutoSaved().length) {
    document.querySelector('.editor-autosaved').style.display = 'block';
    document.querySelector('.js-restore-autosaved').addEventListener('click', restoreAutosaved);
    document.querySelector('.js-discard-autosaved').addEventListener('click', discardAutosaved);
  }
}

function doInit(ContentTools) {
  destroy();

  ContentTools.StylePalette.add([
    new ContentTools.Style('Float aus', 'u-clear-both'),
    new ContentTools.Style('Extra Abstand oben 1', 'u-margin-top-1'),
    new ContentTools.Style('Extra Abstand oben 2', 'u-margin-top-2'),
    new ContentTools.Style('Extra Abstand oben 3', 'u-margin-top-3'),
    new ContentTools.Style('Extra Abstand oben 4', 'u-margin-top-4'),
    new ContentTools.Style('Extra Abstand oben 5', 'u-margin-top-5'),
    new ContentTools.Style('Extra Abstand unten 1', 'u-margin-bottom-1'),
    new ContentTools.Style('Extra Abstand unten 2', 'u-margin-bottom-2'),
    new ContentTools.Style('Extra Abstand unten 3', 'u-margin-bottom-3'),
    new ContentTools.Style('Extra Abstand unten 4', 'u-margin-bottom-4'),
    new ContentTools.Style('Extra Abstand unten 5', 'u-margin-bottom-5'),
  ]);

  ContentTools.IMAGE_UPLOADER = ImageUploader;

  editor = ContentTools.EditorApp.get();
  editor.init('.content-area', 'data-id');
  editor.addEventListener('saved', onSaved);

  const startTimer = () => autoSaveTimer = setTimeout(() => editor.save(true) && startTimer(), 10 * 1000);
  editor.addEventListener('start', () => {
    startTimer();
    [...document.querySelectorAll('.content-area')].forEach((elem) => elem.classList.add('is-editing'));
  });
  editor.addEventListener('stop', (ev) => {
    clearTimeout(autoSaveTimer);
    [...document.querySelectorAll('.content-area')].forEach((elem) => elem.classList.remove('is-editing'));
    if (ev.detail().save === false && contentRestored === true) {
      discardAutosaved();
    }
  });

  handleAutosaved();

  initializing = null;
}

function requireIniitalized() {
  if (!editor) {
    return init();
  }
  return Promise.resolve();
}

/**
 * Initializes the editor
 */
export function init() {
  if ([...document.querySelectorAll('.content-area[data-id]')].length) {
    if (!initializing) {
      initializing = import('../loaders/content-tools').then((mod) => doInit(mod.default));
    }
    return initializing;
  }
}

/**
 * Destroys the editor
 */
export function destroy() {
  clearTimeout(autoSaveTimer);
  if (editor) {
    editor._children.forEach((child) => child.unmount && child.unmount());
    editor._children = [];
    editor._bindings = [];
    editor.destroy();
    editor = null;
  }
}
