let map, cachePluginDom;

export function init() {
  const pluginDom = document.querySelector('.about-map-plugin');
  if (pluginDom) {
    const latLng = JSON.parse(pluginDom.getAttribute('data-latlng'));
    if (latLng && latLng.lat && latLng.lng) {
      if (map && cachePluginDom) {
        document.querySelector('.about-map').replaceChild(cachePluginDom, pluginDom);
      } else {
        const LatLng = new google.maps.LatLng(latLng);
        const myOptions = {
          zoom: 16,
          center: LatLng,
          mapTypeId: google.maps.MapTypeId.HYBRID,
          mapId: 'shanty_basecamp',
        };
        map = new google.maps.Map(pluginDom, myOptions);
        google.maps.importLibrary(`marker`).then(({AdvancedMarkerElement}) => {
          new AdvancedMarkerElement({
            map,
            position: LatLng,
            title: "Shanty-Chor Bedburg's Trockendock",
          });
        });
      }
    }
  }
}

export function destroy() {
  const pluginDom = document.querySelector('.about-map-plugin');
  if (pluginDom) {
    cachePluginDom = document.querySelector('.about-map').removeChild(pluginDom);
  }
}
