/**
 * Throttles fn until the next animation frame
 * @param {Function} fn
 * @returns {Function}
 */
export function frameThrottle(fn) {
  if (typeof fn !== 'function') {
    throw new Error('Invalid function given');
  }
  return () => {
    let ticking = false;
    if (!ticking) {
      window.requestAnimationFrame(() => {
        fn();
        ticking = false;
      });
    }
    ticking = true;
  };
}

/**
 * @param {Function} func
 * @param {Number} wait
 * @param {Boolean} immediate
 * @returns {Function}
 */
export function debounce(func, wait, immediate = false) {
  let timeout;
  return () => {
    const context = this;
    const args = arguments;
    const later = () => {
      timeout = null;
      if (!immediate) {
        func.apply(context, args);
      }
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) {
      func.apply(context, args);
    }
  };
}

export function formatIsoDate(isoDate) {
  return isoDate.split('-').reverse().join('.');
}

export function formatIsoDateTime(isoDateTime) {
  const [isoDate, time] = isoDateTime.split('T');
  return `${formatIsoDate(isoDate)} ${time.slice(0, 5)}`;
}
