/**
 * Makes the email links visible and clickable
 */
export function init() {
  [...document.querySelectorAll('a[data-email-protection]')].forEach((anchor) => {
    const href = atob(anchor.getAttribute('data-email-protection'));
    anchor.href = `mailto:${href}`;
    anchor.innerHTML = href;
  });
}

export function destroy() {
  //do nothing
}
