function create(message) {
  const dlg = document.createElement('dialog');
  dlg.classList.add('dialog');

  const msg = document.createElement('div');
  msg.classList.add('dialog-message');
  msg.innerHTML = message;
  dlg.appendChild(msg);

  const interaction = document.createElement('div');
  interaction.classList.add('dialog-interaction');
  dlg.appendChild(interaction);

  const buttons = document.createElement('div');
  buttons.classList.add('dialog-buttons');
  dlg.appendChild(buttons);

  document.body.appendChild(dlg);

  return [dlg, interaction, buttons];
}

function createButton(text, action, css = '') {
  const button = document.createElement('button');
  button.classList.add('btn');
  css.split(' ').forEach((cs) => {
    cs = cs.trim();
    if (cs) {
      button.classList.add(cs);
    }
  });
  button.innerHTML = text;
  button.addEventListener('click', action);
  return button;
}

/**
 * @param {HTMLElement} dlg
 */
function dispose(dlg) {
  dlg.close();
  dlg.remove();
}

const defaultOptions = {
  cancelButtonCss: 'btn-outline-primary',
  cancelText: 'Abbrechen',
  confirmButtonCss: 'btn-primary',
  confirmText: 'Ok',
  defaultValue: '',
  extraInputs: [],
};

export function confirm(message, options = null) {
  options = Object.assign({}, defaultOptions, options);
  return new Promise((resolve) => {
    const [dlg, interaction, buttons] = create(message);

    const confBtn = createButton(options.confirmText, () => {
      dispose(dlg);
      resolve(true);
    }, options.confirmButtonCss);
    buttons.appendChild(confBtn);

    buttons.appendChild(createButton(options.cancelText, () => {
      dispose(dlg);
      resolve(false);
    }, options.cancelButtonCss + ' btn-sm'));

    dlg.showModal();

    confBtn.focus();
  });
}

export function prompt(message, options = null) {
  options = Object.assign({}, defaultOptions, options);
  return new Promise((resolve) => {
    const [dlg, interaction, buttons] = create(message);

    const input = document.createElement('input');
    input.value = options.defaultValue;
    interaction.appendChild(input);

    const done = () => {
      resolve(input.value);
      dispose(dlg);
    };

    input.addEventListener('keyup', (event) => {
      if (event.keyCode === 13) {
        done();
      }
    });

    options.extraInputs.forEach((inp) => interaction.appendChild(inp));

    buttons.appendChild(createButton(options.confirmText, done, options.confirmButtonCss));

    buttons.appendChild(createButton(options.cancelText, () => {
      dispose(dlg);
      resolve(null);
    }, options.cancelButtonCss + ' btn-sm'));

    dlg.showModal();

    input.focus();
  });
}

export function alert(message, options = null) {
  options = Object.assign({}, defaultOptions, options);
  const [dlg, interaction, buttons] = create(message);
  const btn = createButton(options.confirmText, () => dispose(dlg), options.confirmButtonCss);
  buttons.appendChild(btn);
  dlg.showModal();
  btn.focus();
}
