import '../css/app.scss';
import plugins from './plugins';
import {generateUrl, getHtml} from './Network/Network';

const disposables = new Set();
let currentPath = '';

/**
 * @param {String} html
 * @param {String} urlToReplace
 */
export function changeHtml(html, urlToReplace = null) {
  onPageUnload();

  const div = document.createElement('div');
  div.innerHTML = html;

  document.title = div.querySelector('title').innerHTML;
  document.querySelector('.container').innerHTML = div.querySelector('.container').innerHTML;

  div.innerHTML = '';

  if (urlToReplace) {
    history.replaceState(undefined, undefined, urlToReplace);
    currentPath = window.location.pathname;
  }

  //Must push onPageLoad to next execution stack to allow window.location to update before initializing.
  // Otherwise window.location will still be on the old URL when a normal page link is clicked.
  setTimeout(onPageLoad, 0);
}

/**
 * @param {String} url
 * @returns {String}
 */
function getPath(url) {
  let path = url.split('#')[0];
  path = path.replace(/^https?:\/\/[^/]+/, '');
  return path;
}

/**
 * @param {String} url
 * @returns {Promise<'ok'|'inPage'|'error'>}
 */
function changePage(url) {
  if (getPath(url) === currentPath) {
    return Promise.resolve('inPage');
  }
  return getHtml(url).then((result) => {
    changeHtml(result);
    return 'ok';
  }).catch((response) => {
    console.error(`Change page to "${url}" failed`, response);
    return 'error';
  });
}

/**
 * @param {String} url
 * @param {object|undefined} params
 */
export function gotoUrl(url, params) {
  const generated = generateUrl(url, params);
  changePage(generated).then((result) => {
    if (result === 'ok') {
      history.pushState({}, document.title.split('|')[0].trim(), generated);
      currentPath = window.location.pathname; //new location only present after history.pushState
    }
  });
}

/**
 * Called when a page link is clicked
 * @param {Event} event
 */
function onPageLinkClicked(event) {
  event.preventDefault();
  event.stopPropagation();
  event.stopImmediatePropagation();
  gotoUrl(event.currentTarget.href);
}

/**
 * Binds all internal links
 * @param {HTMLElement} target
 */
export function bindPageLinks(target) {
  const site = window.location.origin;
  const anchors = [...target.querySelectorAll('a:not([target])')];
  if (target.nodeName.toLowerCase() === 'a' && !target.target) {
    anchors.push(target);
  }
  anchors.forEach((anchor) => {
    if (anchor.href.indexOf(site) === 0 && !anchor.classList.contains('js-no-ajax-load') && !anchor.disabled) {
      anchor.addEventListener('click', onPageLinkClicked);
      disposables.add(() => anchor.removeEventListener('click', onPageLinkClicked));
    }
  });
}

/**
 * Called when the page is left
 */
function onPageUnload() {
  plugins.forEach((plugin) => plugin.destroy());
  disposables.forEach((fn) => fn());
  disposables.clear();
}

/**
 * Called when the page is loaded
 */
function onPageLoad() {
  plugins.forEach((plugin) => plugin.init());
  bindPageLinks(document);
}

const inPagePopstateListener = new Set();

export function onInPagePopstate(listener) {
  inPagePopstateListener.add(listener);
  return () => {
    inPagePopstateListener.delete(listener);
  };
}

window.addEventListener('load', () => {
  onPageLoad();
  currentPath = window.location.pathname;
  history.replaceState({}, document.title.split('|')[0].trim(), window.location.href);
  window.addEventListener('popstate', () => {
    changePage(window.location.href).then((result) => {
      if (result === 'ok') {
        currentPath = window.location.pathname;
      } else if (result === 'inPage') {
        inPagePopstateListener.forEach((listener) => listener());
      }
    });
  });
});
