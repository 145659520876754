import {del, post} from '../Network/Network';
import {initDropArea} from '../DragAndDrop/FileDropUtilities';
import Sortable from '../DragAndDrop/Sortable';
import * as Dialogs from '../Dialogs';

let disposables = [];
let sortable;

/**
 * @returns {Number}
 */
function getPlaylistId() {
  const container = document.querySelector('.js-playlist-container');
  return parseInt(container.getAttribute('data-id'), 10);
}

function deleteSong(dom) {
  const songId = parseInt(dom.getAttribute('data-id'), 10);
  const name = dom.getAttribute('data-title');
  Dialogs.confirm(`Möchtest du wirklich '${name}' löschen?`).then((confirmed) => {
    if (confirmed) {
      del('playlist_song_delete', {playlistId: getPlaylistId(), songId})
        .then(() => dom.parentElement.removeChild(dom));
    }
  });
}

function saveSongOrder() {
  const songIds = [...document.querySelectorAll('.playlist-song')].map(
    (song) => parseInt(song.getAttribute('data-id'), 10));
  post('playlist_reorder', {playlistId: getPlaylistId()}, {songIds});
}

export function init() {
  destroy();
  const container = document.querySelector('.js-playlist-container');
  if (container) {
    console.debug(`Initializing PlaylistEdit ...`);

    disposables.push(initDropArea(
      'playlist_song_add',
      {playlistId: getPlaylistId()},
      init, //onStackFinished
    ));

    const songs = [...document.querySelectorAll('.playlist-song')];

    songs.forEach((dom) => {
      const removeBtn = dom.querySelector('.js-delete-song');
      const handler = deleteSong.bind(null, dom);
      removeBtn.addEventListener('click', handler);
      disposables.push(() => removeBtn.removeEventListener('click', handler));
    });

    if (songs.length) {
      sortable = new Sortable(songs, saveSongOrder);
      disposables.push(() => {
        sortable.destroy();
        sortable = null;
      });
    }
  }
}

export function destroy() {
  disposables.forEach((fn) => fn());
  disposables = [];
}
