import * as DragUtilities from './DragUtilities';

const callback = Symbol();
const elementsSymbol = Symbol();
const dragStart = Symbol();
const dragOver = Symbol();
const dragEnd = Symbol();
const draggedElement = Symbol();
const startIndex = Symbol();
const handleSelector = Symbol();
const dragHandle = Symbol();
const handleParent = Symbol();

const noop = () => {/*noop*/
};

/**
 * Gets the index of the currently dragged element dom
 * @returns {number}
 */
function getIndex(element) {
  return [...element.parentElement.children].indexOf(element);
}

/**
 * @param {HTMLElement} target
 * @param {DragEvent} event
 * @return {number[2]}
 */
function getPositionOffset(target, event) {
  const rect = target.getBoundingClientRect();
  const x = event.pageX - rect.left;
  const y = event.pageY - rect.top;
  return [x, y];
}

/**
 * @param {DragEvent} event
 */
function onDragStart(event) {
  event.stopPropagation();
  event.dataTransfer.effectAllowed = 'move';
  event.dataTransfer.setData('text', ''); //this is required

  this[draggedElement] = event.currentTarget[handleParent];
  this[startIndex] = getIndex(event.currentTarget[handleParent]);

  if (event.currentTarget !== this[draggedElement] && event.dataTransfer.setDragImage) {
    event.dataTransfer.setDragImage(this[draggedElement], ...getPositionOffset(this[draggedElement], event));
  }
}

/**
 * @param {DragEvent} event
 */
function onDragOver(event) {
  if (this[draggedElement] && this[elementsSymbol].indexOf(event.currentTarget) !== -1) {
    event.stopPropagation();
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';

    this[draggedElement].style.opacity = 0;

    DragUtilities.moveDraggedItemToTarget(event.currentTarget, this[draggedElement]);
  }
}

/**
 * @param {DragEvent} event
 */
function onDragEnd(event) {
  event.stopPropagation();
  const oldIndex = this[startIndex];
  const newIndex = getIndex(event.currentTarget[handleParent]);
  this[draggedElement].style.opacity = '';
  this[draggedElement] = null;
  if (oldIndex !== newIndex) {
    this[callback](oldIndex, newIndex);
  }
}

export default class Sortable {

  /**
   * @param {HTMLElement[]} elements
   * @param {Function} onOrderChanged [optional] callback called with (oldIndex, newIndex)
   * @param {String} handle [optional] selector for the drag handle
   */
  constructor(elements, onOrderChanged = noop, handle = null) {
    this[elementsSymbol] = [...elements];
    this[callback] = onOrderChanged;
    this[handleSelector] = handle;

    this[dragStart] = onDragStart.bind(this);
    this[dragOver] = onDragOver.bind(this);
    this[dragEnd] = onDragEnd.bind(this);

    this.init();
  }

  /**
   * Initializes dragging
   */
  init() {
    this[elementsSymbol].forEach((element) => {
      const handle = this[handleSelector] ? element.querySelector(this[handleSelector]) : element;
      element[dragHandle] = handle;
      handle[handleParent] = element;
      handle.setAttribute('draggable', true);
      handle.addEventListener('dragstart', this[dragStart]);
      element.addEventListener('dragover', this[dragOver]);
      handle.addEventListener('dragend', this[dragEnd]);
    });
  }

  /**
   * Destroys the dragging
   */
  destroy() {
    this[elementsSymbol].forEach((element) => {
      const handle = element[dragHandle];
      delete handle[handleParent];
      delete element[dragHandle];
      handle.removeAttribute('draggable');
      handle.removeEventListener('dragstart', this[dragStart]);
      element.removeEventListener('dragover', this[dragOver]);
      handle.removeEventListener('dragend', this[dragEnd]);
    });
  }

}
