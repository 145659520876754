import {post} from './Network/Network';
import {debounce} from './Utilities';

let disposables = [];

export function init() {
  destroy();

  [...document.querySelectorAll('.js-auto-save-form')].forEach((form) => {
    const save = debounce(() => post(form.action, {}, new FormData(form)), 100);
    [...form.querySelectorAll('input, select, textarea')].forEach((input) => {
      input.addEventListener('change', save);
      disposables.push(() => input.removeEventListener('change', save));
    });
  });
}

export function destroy() {
  disposables.forEach((fn) => fn());
  disposables = [];
}
