function get(storage, key, defaultValue) {
  const item = storage.getItem(key);
  return item !== null ? JSON.parse(item) : defaultValue;
}

function set(storage, key, value) {
  storage.setItem(key, JSON.stringify(value));
}

export function getLocal(key, defaultValue = null) {
  return get(localStorage, key, defaultValue);
}

export function setLocal(key, value) {
  set(localStorage, key, value);
}

export function getSession(key, defaultValue = null) {
  return get(sessionStorage, key, defaultValue);
}

export function setSession(key, value) {
  set(sessionStorage, key, value);
}
