import 'whatwg-fetch';
import routes from './routes';
import {alert} from '../Dialogs';

const GET = 'get';
const POST = 'post';
const PATCH = 'patch';
const PUT = 'put';
const DELETE = 'delete';

const app = window.location.href.match(/(\/app_[a-z]+\.php)/);
const routePrefix = app ? app[1] : '';

/**
 * Turns a url route ident into the url pattern
 * @param {string} url
 * @return {string}
 */
function getUrlPattern(url) {
  if (url.indexOf('/') !== -1) {
    //If the url already has slashes inside, it is a pattern already
    return url;
  } else if (routes[url]) {
    return routePrefix + routes[url];
  } else {
    throw new Error('Unable to find URL for "' + url + '"');
  }
}

/**
 * Replaces the parameters in the URL
 *
 * @param {string} url
 * @param {object|undefined} parameters
 * @returns {string}
 */
export function generateUrl(url, parameters, hash = '') {
  if (typeof url !== 'string' || !url) {
    throw new Error('Invalid URL type');
  }

  url = getUrlPattern(url);

  if (!parameters) {
    return url;
  }

  if (typeof parameters !== 'object') {
    throw new Error('Invalid parameters');
  }

  const urlParams = [];

  Object.keys(parameters).forEach((key) => {
    const keyStr = '{' + key + '}';
    if (url.indexOf(keyStr) > -1) {
      url = url.replace(keyStr, encodeURIComponent(parameters[key]));
    } else {
      urlParams.push(encodeURIComponent(key) + '=' + encodeURIComponent(parameters[key]));
    }
  });

  if (urlParams.length) {
    url = url + '?' + urlParams.join('&');
  }

  const matches = url.match(/\{[^}]+\}/g);
  if (matches !== null) {
    throw new Error('There are still ' + matches.length + ' unsatisfied url parameters: ' + matches.join(', '));
  }

  if (hash) {
    url = `${url}#${hash}`;
  }

  return url;
}

/**
 * @returns {{Accept: string, Content-Type: string}}
 */
export function getDefaultHeader() {
  return {
    'Accept': 'application/json',
  };
}

/**
 * @param {string} method
 * @param {string} url
 * @param {Record<string, string|number>} urlParams
 * @param {FormData|Record<string, unknown>|null} data
 * @param {{headers?: object; returnResponse?: boolean; noRedirect?: boolean; }} options
 * @returns {Promise}
 */
function request(method, url, urlParams, data, {
  headers = getDefaultHeader(),
  noRedirect,
  returnResponse,
} = {}) {
  url = generateUrl(url, urlParams);

  const fetchSettings = {method, headers, credentials: 'include'};

  if (noRedirect) {
    fetchSettings.redirect = 'manual';
  }

  if (data instanceof FormData) {
    fetchSettings.body = data;
    // fetchSettings.headers['Content-Type'] = 'application/x-www-form-urlencoded';
  } else if (data) {
    fetchSettings.body = JSON.stringify(data);
    fetchSettings.headers['Content-Type'] = 'application/json';
  }

  const expectsJson = headers['Accept'] && headers['Accept'] === 'application/json';

  return fetch(url, fetchSettings).then((response) => {
    if (response.status >= 200 && response.status < 300) {
      if (returnResponse) {
        return response;
      } else if (response.status === 204) {
        return null;
      } else if (expectsJson) {
        return response.json();
      } else {
        return response.text();
      }
    } else if (noRedirect && response.type === 'opaqueredirect') {
      return null;
    } else {
      return Promise.reject(response);
    }
  }).catch((response) => {
    if (response && response.status === 400) {
      response.text().then(alert);
    } else {
      alert('Ein unerwarteter Fehler ist aufgetreten! Bitte kontaktiere den Administrator!');
      console.error('Network request error', method, url, urlParams, data, headers, returnResponse, response);
    }
  });
}

/**
 * @param {string} url
 * @param {Record<string, string|number>} urlParams
 * @returns {Promise}
 */
export function getHtml(url, urlParams = null) {
  return request(GET, url, urlParams, null, {headers: {'Accept': 'text/html'}});
}

/**
 * @param {string} url
 * @param {Record<string, string|number>} urlParams
 * @param {{headers?: object; returnResponse?: boolean; noRedirect?: boolean; }} options
 * @returns {Promise}
 */
export function get(url, urlParams = null, options) {
  return request(GET, url, urlParams, null, options);
}

/**
 * @param {string} url
 * @param {Record<string, string|number>} urlParams
 * @param {FormData|Record<string, unknown>|null} data
 * @param {{headers?: object; returnResponse?: boolean; noRedirect?: boolean; }} options
 * @returns {Promise}
 */
export function del(url, urlParams = null, data = null, options) {
  return request(DELETE, url, urlParams, data, options);
}

/**
 * @param {string} url
 * @param {Record<string, string|number>} urlParams
 * @param {FormData|Record<string, unknown>|null} data
 * @param {{headers?: object; returnResponse?: boolean; noRedirect?: boolean; }} options
 * @returns {Promise}
 */
export function post(url, urlParams = null, data = null, options) {
  return request(POST, url, urlParams, data, options);
}

/**
 * @param {string} url
 * @param {Record<string, string|number>} urlParams
 * @param {FormData|Record<string, unknown>|null} data
 * @param {{headers?: object; returnResponse?: boolean; noRedirect?: boolean; }} options
 * @returns {Promise}
 */
export function patch(url, urlParams = null, data = null, options) {
  return request(PATCH, url, urlParams, data, options);
}

/**
 * @param {string} url
 * @param {Record<string, string|number>} urlParams
 * @param {FormData|Record<string, unknown>|null} data
 * @param {{headers?: object; returnResponse?: boolean; noRedirect?: boolean; }} options
 * @returns {Promise}
 */
export function put(url, urlParams = null, data = null, options) {
  return request(PUT, url, urlParams, data, options);
}
