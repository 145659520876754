import {patch} from '../Network/Network';
import {getLastSelectedStartDayjs} from './Calendar';
import {Modal} from 'bootstrap';
import {onInPagePopstate} from '../app';

let disposables = [];

function initList() {
  const openAttendanceModal = initAttendanceModal();
  const datesById = new Map();
  const dates = [...document.querySelectorAll('.dates-date')];
  dates.forEach((date) => {
    const id = parseInt(date.getAttribute('data-id'));
    datesById.set(id, date);
    const header = date.querySelector('.dates-date-header');
    const toggle = () => {
      if (date.classList.contains('dates-date--open')) {
        date.classList.remove('dates-date--open');
        history.pushState({}, '', location.pathname);
      } else {
        dates.forEach((curDate) => curDate.classList.remove('dates-date--open'));
        date.classList.add('dates-date--open');
        history.pushState({}, '', `#t-${id}`);
      }
    };
    header.addEventListener('click', toggle);
    disposables.push(() => header.removeEventListener('click', toggle));

    const editAnchor = header.querySelector('.dates-date-header-edit-a');
    if (editAnchor) {
      const stopPropagation = (e) => e.stopPropagation();
      editAnchor.addEventListener('click', stopPropagation);
      disposables.push(() => editAnchor.removeEventListener('click', stopPropagation));
    }

    bindSetAttendance(date, true);
    bindSetAttendance(date, false);
    openAttendanceModal && bindOpenAttendanceModal(date, openAttendanceModal);
  });

  const setDateOpenByHash = () => {
    dates.forEach((curDate) => curDate.classList.remove('dates-date--open'));
    const matches = location.hash.match(/^#t-(\d+)$/);
    if (matches) {
      const dateId = parseInt(matches[1]);
      const date = datesById.get(dateId);
      if (date) {
        date.classList.add('dates-date--open');
      } else {
        history.replaceState({}, '', location.pathname);
      }
    }
  };
  setDateOpenByHash();
  disposables.push(onInPagePopstate(setDateOpenByHash));
}

/**
 * @param {HTMLButtonElement} date
 * @param {boolean} attending
 */
function bindSetAttendance(date, attending) {
  [...date.querySelectorAll(attending ? '.js-btn-attend' : '.js-btn-notAttend')].forEach((button) => {
    const listener = async () => {
      if (button.disabled) {
        return;
      }
      button.disabled = true;
      const html = button.innerHTML;
      button.innerHTML = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>';
      const currentUserId = parseInt(document.querySelector('.js-page-dates').getAttribute('data-user-id'));
      await setAttendance(date, currentUserId, +attending);
      button.innerHTML = html;
      button.disabled = false;
    };
    button.addEventListener('click', listener);
    disposables.push(() => button.removeEventListener('click', listener));
  });
}

/**
 * @returns {(date: HTMLDivElement) => void}
 */
function initAttendanceModal() {
  const modal = document.querySelector('#attendanceModal');
  if (!modal) {
    return null;
  }

  //Add a class to the content, so we can hide stuff in print view for the modal:
  const content = document.querySelector('.content');
  const onShown = () => content.classList.add('content-attendanceModal-is-visible');
  const onHidden = () => content.classList.remove('content-attendanceModal-is-visible');
  modal.addEventListener('shown.bs.modal', onShown);
  modal.addEventListener('hide.bs.modal', onHidden);
  disposables.push(() => {
    modal.removeEventListener('shown.bs.modal', onShown);
    modal.removeEventListener('hide.bs.modal', onHidden);
  });

  const instance = new Modal(modal);
  disposables.push(() => {
    instance.hide();
    instance.dispose();
  });

  /** @type {HTMLDivElement|null} */
  let date = null;
  let isResetting = false;

  const onChange = (input, userId, attending) => {
    const listener = async () => {
      if (date && !isResetting && input.checked) {
        await setAttendance(date, userId, attending);
        updateCounts(date);
      }
    };
    input.addEventListener('change', listener);
    disposables.push(() => input.removeEventListener('change', listener));
  };

  const nameSpan = modal.querySelector('.attendanceModal-title-name');
  const dateSpan = modal.querySelector('.attendanceModal-title-date');

  const radios = [...modal.querySelectorAll('.js-editAttendance-radios')].map((div) => {
    const userId = parseInt(div.getAttribute('data-user-id'));
    const userVoice = div.getAttribute('data-user-voice');
    const attending = div.querySelector('.js-editAttendance-attending');
    const unknown = div.querySelector('.js-editAttendance-unknown');
    const unknownIconQuestion = div.querySelector('.js-editAttendance-unknown-icon-question');
    const unknownIconAbsent = div.querySelector('.js-editAttendance-unknown-icon-absent');
    const notAttending = div.querySelector('.js-editAttendance-notAttending');
    onChange(attending, userId, 1);
    onChange(unknown, userId, -1);
    onChange(notAttending, userId, 0);
    return {userId, userVoice, attending, unknown, unknownIconQuestion, unknownIconAbsent, notAttending};
  });

  const countDivs = {
    instrumental: {
      attending: modal.querySelector('.attendanceModal-sum-instrumental .attending'),
      notAttending: modal.querySelector('.attendanceModal-sum-instrumental .notAttending'),
    },
    tenor1: {
      attending: modal.querySelector('.attendanceModal-sum-tenor1 .attending'),
      notAttending: modal.querySelector('.attendanceModal-sum-tenor1 .notAttending'),
    },
    tenor2: {
      attending: modal.querySelector('.attendanceModal-sum-tenor2 .attending'),
      notAttending: modal.querySelector('.attendanceModal-sum-tenor2 .notAttending'),
    },
    base: {
      attending: modal.querySelector('.attendanceModal-sum-base .attending'),
      notAttending: modal.querySelector('.attendanceModal-sum-base .notAttending'),
    },
    total: {
      attending: modal.querySelector('.attendanceModal-sum-total .attending'),
      notAttending: modal.querySelector('.attendanceModal-sum-total .notAttending'),
    },
  };

  function updateCounts(dateDiv) {
    const counts = {
      instrumental: {attending: 0, notAttending: 0},
      tenor1: {attending: 0, notAttending: 0},
      tenor2: {attending: 0, notAttending: 0},
      base: {attending: 0, notAttending: 0},
      total: {attending: 0, notAttending: 0},
    };
    const data = getDateData(dateDiv);
    radios.forEach(({userId, userVoice}) => {
      const isAbsent = data.absent.hasOwnProperty(userId);
      const isAttending = data.attending.hasOwnProperty(userId);
      const isNotAttending = data.notAttending.hasOwnProperty(userId);
      if (isAttending) {
        counts[userVoice].attending++;
        counts.total.attending++;
      } else if (isNotAttending || isAbsent) {
        counts[userVoice].notAttending++;
        counts.total.notAttending++;
      }
    });
    Object.entries(countDivs).forEach(([group, divs]) => {
      Object.entries(divs).forEach(([key, div]) => {
        div.innerHTML = counts[group][key];
      });
    });
  }

  return (dateDiv) => {
    date = dateDiv;
    isResetting = true;
    const data = getDateData(date);
    nameSpan.innerHTML = data.subject;
    dateSpan.querySelector('.attendanceModal-title-date-dotw').innerHTML = data.dateDotw;
    dateSpan.querySelector('.attendanceModal-title-date-date').innerHTML = data.dateDate;
    dateSpan.querySelector('.attendanceModal-title-date-time').innerHTML = data.dateTime;
    radios.forEach(({userId, attending, unknown, unknownIconQuestion, unknownIconAbsent, notAttending}) => {
      const isAbsent = data.absent.hasOwnProperty(userId);
      const isAttending = data.attending.hasOwnProperty(userId);
      const isNotAttending = data.notAttending.hasOwnProperty(userId);
      attending.checked = isAttending;
      unknown.checked = !isAttending && !isNotAttending;
      notAttending.checked = isNotAttending;
      unknownIconQuestion.style.display = isAbsent ? 'none' : '';
      unknownIconAbsent.style.display = isAbsent ? '' : 'none';
    });
    updateCounts(dateDiv);
    isResetting = false;
    instance.show();
  };
}

/**
 * @param {HTMLDivElement} date
 * @param {(date: HTMLDivElement) => void} openAttendanceModal
 */
function bindOpenAttendanceModal(date, openAttendanceModal) {
  const listener = (e) => {
    e.stopPropagation();
    openAttendanceModal(date);
  };

  const attendance = date.querySelector('.dates-date-attendance');
  attendance.addEventListener('click', listener);
  disposables.push(() => attendance.removeEventListener('click', listener));

  [...date.querySelectorAll('.js-btn-editAttendance')].forEach((button) => {
    button.addEventListener('click', listener);
    disposables.push(() => button.removeEventListener('click', listener));
  });
}

/**
 * @param {HTMLDivElement} date
 * @param {number} userId
 * @param {number} attending -1|0|1
 */
async function setAttendance(date, userId, attending) {
  const data = getDateData(date);
  const response = await patch('date_setAttendance', {dateId: data.id, userId, attending});
  data.attending = response.attending;
  data.notAttending = response.notAttending;
  const currentUserId = parseInt(document.querySelector('.js-page-dates').getAttribute('data-user-id'));
  const attendingNames = mapAttendanceNames(response.attending, currentUserId);
  const notAttendingNames = mapAttendanceNames(response.notAttending, currentUserId);
  date.querySelector('.dates-date-attendance .attending').innerHTML = attendingNames.length;
  date.querySelector('.dates-date-attendance .notAttending').innerHTML = notAttendingNames.length;
  date.querySelector('.js-is-attending').style.display = attending === 1 ? '' : 'none';
  date.querySelector('.js-is-not-attending').style.display = attending === 0 ? '' : 'none';
  date.querySelector('.js-is-undecided').style.display = attending === -1 ? '' : 'none';
}

/**
 * @param {Record<number, string>} list
 * @returns {HTMLSpanElement[]}
 */
function mapAttendanceNames(list, currentUserId) {
  return Object.entries(list).sort((a, b) => a[1].localeCompare(b[1])).map(([userId, name]) => {
    return `<span class="${currentUserId === +userId ? 'fw-bold' : ''}">${name}</span>`;
  });
}

const dateData = Symbol();

/**
 * @param {HTMLDivElement} date
 * @returns {{
 *   id: number;
 *   attending: Record<number, string|undefined>;
 *   notAttending: Record<number, string|undefined>;
 *   subject: string;
 *   dateFull: string;
 *   dateDotw: string;
 *   dateDate: string;
 *   dateTime: string;
 * }}
 */
function getDateData(date) {
  if (!date[dateData]) {
    date[dateData] = {
      absent: JSON.parse(date.getAttribute('data-absent')),
      attending: JSON.parse(date.getAttribute('data-attending')),
      dateFull: date.getAttribute('data-dateFull'),
      dateDotw: date.getAttribute('data-dateDotw'),
      dateDate: date.getAttribute('data-dateDate'),
      dateTime: date.getAttribute('data-dateTime'),
      id: parseInt(date.getAttribute('data-id')),
      notAttending: JSON.parse(date.getAttribute('data-notAttending')),
      subject: date.querySelector('.dates-date-header-subject').innerText,
    };
  }
  return date[dateData];
}

function initEdit(editIdDiv) {
  const isNew = Boolean(+editIdDiv.getAttribute('data-is-new'));
  const lastStartDayjs = getLastSelectedStartDayjs();

  const subject = document.getElementById('subject');
  const plannedRadio = document.getElementById('planned');
  const regularRadio = document.getElementById('regular');
  const rehearsalRadio = document.getElementById('rehearsal');
  const isInternalCheckbox = document.getElementById('is_internal');
  if (subject && plannedRadio && regularRadio && rehearsalRadio && isInternalCheckbox) {
    const onTypeChange = () => {
      if (rehearsalRadio.checked && subject.value === '') {
        subject.value = 'Chorprobe';
      }
      if (rehearsalRadio.checked) {
        isInternalCheckbox.disabled = true;
        isInternalCheckbox.checked = true;
      } else {
        isInternalCheckbox.disabled = false;
      }
    };
    plannedRadio.addEventListener('change', onTypeChange);
    regularRadio.addEventListener('change', onTypeChange);
    rehearsalRadio.addEventListener('change', onTypeChange);
    disposables.push(() => plannedRadio.removeEventListener('change', onTypeChange));
    disposables.push(() => regularRadio.removeEventListener('change', onTypeChange));
    disposables.push(() => rehearsalRadio.removeEventListener('change', onTypeChange));
    //run once to set the isInternal disabled state initially:
    onTypeChange();
  }

  const year = document.getElementById('date_on_year');
  const month = document.getElementById('date_on_month');
  const day = document.getElementById('date_on_day');
  const visYear = document.getElementById('visible_from_on_year');
  const visMonth = document.getElementById('visible_from_on_month');
  const visDay = document.getElementById('visible_from_on_day');
  const onChange = () => {
    const date = new Date(year.value, month.value - 1, day.value - (8 * 7));
    visYear.value = date.getFullYear();
    visMonth.value = date.getMonth() + 1;
    visDay.value = date.getDate();
  };

  if (isNew && lastStartDayjs) {
    year.value = lastStartDayjs.year();
    month.value = lastStartDayjs.month() + 1;
    day.value = lastStartDayjs.date();
    onChange();
  }

  day.addEventListener('change', onChange);
  disposables.push(() => day.removeEventListener('change', onChange));
  month.addEventListener('change', onChange);
  disposables.push(() => month.removeEventListener('change', onChange));
  year.addEventListener('change', onChange);
  disposables.push(() => year.removeEventListener('change', onChange));
}

export function init() {
  destroy();
  const list = document.querySelector('.js-page-dates');
  const edit = document.querySelector('.js-page-date-edit');
  list && initList();
  edit && initEdit(edit);
}

export function destroy() {
  disposables.forEach((fn) => fn());
  disposables = [];
}
