import FileUploader from '../Network/FileUploader';
import {generateUrl} from '../Network/Network';
import {bindPageLinks} from '../app';

const uploadStack = [];

function walkFileSystem(directory, callback, error) {
  if (!callback.pending) {
    callback.pending = 0;
  }
  if (!callback.files) {
    callback.files = [];
  }

  callback.pending++;

  const reader = directory.createReader();
  const relativePath = directory.fullPath.replace(/^\//, '').replace(/(.+?)\/?$/, '$1/');
  reader.readEntries(entries => {
    callback.pending--;
    [...entries].forEach((entry) => {
      if (entry.isFile) {
        callback.pending++;
        entry.file(file => {
          file.relativePath = relativePath;
          callback.files.push(file);
          if (--callback.pending === 0) {
            callback(callback.files);
          }
        }, error);
      } else {
        walkFileSystem(entry, callback, error);
      }
    });

    if (callback.pending === 0) {
      callback(callback.files);
    }
  }, error);
}

/**
 * In Chrome >= 21 this fetches the first item entry off the transfer items
 * @return {Object|NULL}
 */
function getWebkitFirstEntry(dataTransfer) {
  if (dataTransfer.items && dataTransfer.items[0] && dataTransfer.items[0].webkitGetAsEntry) {
    return dataTransfer.items[0].webkitGetAsEntry();
  }
  return null;
}

/**
 * Gathers all files to upload in an array
 * @param {DataTransfer} dataTransfer
 * @return {Promise}
 */
function gatherFiles(dataTransfer) {
  return new Promise((resolve, reject) => {
    const firstEntry = getWebkitFirstEntry(dataTransfer);
    if (firstEntry) {
      // Experimental way of uploading entire folders (only supported by chrome >= 21)
      walkFileSystem(firstEntry.filesystem.root, resolve, reject);
    } else {
      //Fallback - filter out folders by size:
      const files = [];
      [...dataTransfer.files].forEach((file) => {
        if (file.size !== 4096 && file.size !== 0) {
          files.push(file);
        }
      });
      resolve(files);
    }
  });
}

/**
 * @return {FileUploader}
 */
function createUploader(route, routeParams) {
  const dom = document.querySelector('.js-fileUpload-dropzone');
  if (!dom) {
    throw new Error('Missing dom node');
  }
  const limits = [
    parseInt(+dom.getAttribute('data-max-file-uploads'), 10),
    parseInt(+dom.getAttribute('data-post-max-size'), 10),
    parseInt(+dom.getAttribute('data-upload-max-file-size'), 10),
  ];
  return new FileUploader(...limits, generateUrl(route, routeParams));
}

function stopEvent(event) {
  event.stopPropagation();
  event.stopImmediatePropagation();
  event.preventDefault();
}

function addDragoverClass(event) {
  event.currentTarget.classList.add('is-dragover');
  stopEvent(event);
}

function removeDragoverClass(event) {
  event.currentTarget.classList.remove('is-dragover');
  stopEvent(event);
}

function onDrop(context, event) {
  gatherFiles(event.dataTransfer).then((files) => {
    if (files.length) {
      addFiles(context, files);
    }
  });
  removeDragoverClass(event);
}

function onDragOver(event) {
  event.dataTransfer.dropEffect = 'copy';
  addDragoverClass(event);
}

/**
 * Called when the user selects files via the <input> element
 * @param {Object} context
 * @param {HTMLElement} form
 * @param {Event} event
 */
function onInputChange(context, form, event) {
  addFiles(context, event.currentTarget.files);
  form.reset();
}

/**
 * Handles the upload stack
 */
function handleStack(context) {
  const next = uploadStack.shift();
  if (next) {
    context.uploader.upload([next.file], (prog) => next.progress.value = prog.percent).then((response) => {
      if (response.dom) {
        const tmp = document.createElement('div');
        tmp.innerHTML = response.dom;
        const newDom = tmp.children[0];
        const oldDomToReplace = newDom.id && document.getElementById(newDom.id);
        if (oldDomToReplace) {
          next.dom.remove();
          oldDomToReplace.parentElement.replaceChild(newDom, oldDomToReplace);
        } else {
          next.dom.parentElement.replaceChild(newDom, next.dom);
        }
        bindPageLinks(newDom);
      } else {
        next.dom.remove();
      }

      context.onFileAdded();

      handleStack(context);
    }).catch((error) => {
      console.error(error, next);

      const errDom = document.createElement('span');
      errDom.classList.add('fileUpload-error');
      errDom.innerHTML = 'Der Upload ist fehlgeschlagen';
      next.dom.replaceChild(errDom, next.progress);

      handleStack(context);
    });
  } else {
    context.onStackFinished();
  }
}

/**
 * @param {FileList|File[]} files
 */
function addFiles(context, files) {
  const itemsContainer = document.querySelector('.js-fileUpload-items');
  [...files].forEach((/** File */file) => {
    if (file.name[0] === '.') {
      console.debug('FileDropUtilities::addFiles - ignoring dot-file');
    } else if (!context.isValidFile(file)) {
      console.debug(`FileDropUtilities::addFiles - ignoring invalid file '${file.name}'`);
    } else {
      const name = document.createElement('span');
      name.innerHTML = `(${file.name})`;
      name.classList.add('fileUpload-name');

      const progress = document.createElement('progress');
      progress.max = 100;
      progress.value = 0;

      const dom = document.createElement('div');
      dom.classList.add('content-container');

      dom.appendChild(progress);
      dom.appendChild(name);

      itemsContainer.appendChild(dom);

      uploadStack.push({file, dom, progress});
    }
  });

  //Set timeout to let the browser render the placeholder before the upload begins:
  setTimeout(() => handleStack(context), 10);
}

const noop = () => {
};

/**
 *
 * @param {String} route
 * @param {Object} routeParams
 * @param {Function} onStackFinished
 * @param {Function} onFileAdded
 * @returns {function(): void}
 */
export function initDropArea(route, routeParams, onStackFinished = noop, onFileAdded = noop) {
  const uploader = createUploader(route, routeParams);
  const dropzone = document.querySelector('.js-fileUpload-dropzone');

  let isValidFile;
  const validExtensionsAttr = dropzone.getAttribute('data-valid-extensions');
  if (validExtensionsAttr === '*') {
    isValidFile = () => true;
  } else {
    const validExtensions = JSON.parse(validExtensionsAttr);
    if (!Array.isArray(validExtensions)) {
      throw new Error('Invalid extensions');
    }
    isValidFile = (file) => validExtensions.indexOf(file.name.split('.').pop().toLowerCase()) !== -1;
  }

  const context = {
    uploader,
    isValidFile,
    onFileAdded,
    onStackFinished,
  };

  const disposables = [];

  const form = dropzone.querySelector('form');
  if (form) {
    const input = dropzone.querySelector('input');
    const handler = onInputChange.bind(null, context, form);
    input.addEventListener('change', handler);
    disposables.push(() => input.removeEventListener('change', handler));
  }

  {
    dropzone.addEventListener('dragenter', addDragoverClass);
    disposables.push(() => dropzone.removeEventListener('dragenter', addDragoverClass));
  }
  {
    dropzone.addEventListener('dragover', onDragOver);
    disposables.push(() => dropzone.removeEventListener('dragover', onDragOver));
  }
  {
    const handler = onDrop.bind(null, context);
    dropzone.addEventListener('drop', handler);
    disposables.push(() => dropzone.removeEventListener('drop', handler));
  }
  {
    dropzone.addEventListener('dragleave', removeDragoverClass);
    disposables.push(() => dropzone.removeEventListener('dragleave', removeDragoverClass));
  }
  {
    dropzone.addEventListener('dragend', removeDragoverClass);
    disposables.push(() => dropzone.removeEventListener('dragend', removeDragoverClass));
  }

  return () => disposables.forEach((fn) => fn());
}
