import {frameThrottle, debounce} from './Utilities';

let active = false;

/**
 * Returns true if there is enough space to enable the sticky navigation
 * @returns {boolean}
 */
function isStickyEnabled() {
  const header = document.querySelector('.main-header').offsetHeight;
  const nav = document.getElementById('sidebar').offsetHeight;
  const win = window.innerHeight;
  return nav < (win - 2 * header);
}

const judgeFixed = frameThrottle(() => {
  const top = document.querySelector('.s-nav-container').getBoundingClientRect().top;
  document.getElementById('sidebar').classList.toggle('sidebar--fixed', top < 0);
});

/**
 * Enables/disables the sticky navigation feature
 */
function toggle() {
  window.removeEventListener('scroll', judgeFixed);
  if (isStickyEnabled()) {
    window.addEventListener('scroll', judgeFixed);
    judgeFixed();
    active = true;
  } else {
    active = false;
    document.getElementById('sidebar').classList.remove('sidebar--fixed');
  }
}

export function init() {
  if (active) {
    judgeFixed();
  }
}

export function destroy() {
  //do nothing
}

window.addEventListener('resize', debounce(toggle, 500));
toggle();
