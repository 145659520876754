import {get} from './Network/Network';

let initialized = false;
let playingIndex = -1;
let audio;
let songs;
let dom;

function markPlaying() {
  dom.playpauseIcon.classList.remove('fa-play');
  dom.playpauseIcon.classList.add('fa-pause');
}

function markPausing() {
  dom.playpauseIcon.classList.remove('fa-pause');
  dom.playpauseIcon.classList.add('fa-play');
}

function setControlsDisabled() {
  dom.prev.classList.toggle('is-disabled', playingIndex <= 0);
  dom.next.classList.toggle('is-disabled', playingIndex >= songs.length - 1);
}

function playCurrentIndex() {
  audio.src = songs[playingIndex].playableFile.url;
  audio.play();
  dom.songText.innerHTML = songs[playingIndex].title;
  setControlsDisabled();
}

function fadeIn(time = 3000, step = 0.1) {
  let interval;
  audio.volume = 0.0;
  interval = setInterval(() => {
    if (audio.volume >= 1.0 || audio.volume + step >= 1.0) {
      clearInterval(interval);
      audio.volume = 1.0;
    } else {
      audio.volume = audio.volume + step;
    }
  }, time * step);
}

function fadeOut(time = 3000, step = 0.1) {
  let interval;
  audio.volume = 1.0;
  interval = setInterval(() => {
    if (audio.volume <= 0.0 || audio.volume - step <= 0.0) {
      clearInterval(interval);
      audio.volume = 0.0;
    } else {
      audio.volume = audio.volume - step;
    }
  }, time * step);
}

function prev() {
  if (playingIndex > 0) {
    playingIndex--;
    playCurrentIndex();
  }
}

function next() {
  if (playingIndex < songs.length - 1) {
    playingIndex++;
    playCurrentIndex();
  }
}

function togglePlayPause() {
  if (dom.playpauseIcon.classList.contains('fa-pause')) {
    audio.pause();
  } else {
    audio.play();
  }
}

function initControls() {
  audio.addEventListener('ended', () => setTimeout(next, 500));
  audio.addEventListener('playing', markPlaying);
  audio.addEventListener('pause', markPausing);

  dom.playpause.addEventListener('click', togglePlayPause);
  dom.prev.addEventListener('click', prev);
  dom.next.addEventListener('click', next);

  dom.container.style.display = '';
}

function initDoms() {
  const container = document.querySelector('.musicplayer-container');
  dom = {
    container,
    songText: container.querySelector('.musicplayer-song span'),
    prev: container.querySelector('.js-previous'),
    playpause: container.querySelector('.js-playpause'),
    playpauseIcon: container.querySelector('.js-playpause i'),
    next: container.querySelector('.js-next'),
  };
}

function onFirstLoad() {
  get('settings_playlist').then(({playlist}) => {
    /** @var {{title: String, songs: Array}} playlist */
    if (playlist) {
      audio = document.createElement('audio');

      //Filter by songs available in something the current browser can play:
      songs = playlist.songs.filter((song) => {
        song.playableFile = song.files.find((file) => audio.canPlayType(file.mimeType));
        return Boolean(song.playableFile);
      });

      //If we have some left, build the new playlist:
      if (songs.length) {
        initDoms();
        initControls();
        next();
        fadeIn(7000, 0.05);
      }
    }
  });

}

function detachPlayer() {
  if (dom) {
    dom.container.parentElement.removeChild(dom.container);
  }
}

function reattachPlayer() {
  if (dom) {
    const placeholder = document.querySelector('.musicplayer-container');
    placeholder.parentElement.replaceChild(dom.container, placeholder);
  }
}

export async function init() {
  if (!document.querySelector('.js-no-music')) {
    if (!initialized) {
      onFirstLoad();
      initialized = true;
    } else {
      reattachPlayer();
    }
  }
}

export function destroy() {
  detachPlayer();
}

window.websitePlayer = {
  audio,
  togglePlayPause,
  next,
  prev,
  fadeIn,
  fadeOut,
};
