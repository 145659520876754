import {del, patch, post} from '../Network/Network';
import {initDropArea} from '../DragAndDrop/FileDropUtilities';
import * as Dialogs from '../Dialogs';

let disposables = [];

/**
 * @returns {Number}
 */
function getFolderId() {
  return parseInt(+document.querySelector('.js-page-files--editable').getAttribute('data-folder-id'), 10);
}

/**
 * @param {HTMLElement} a
 * @param {HTMLElement} b
 * @returns {number}
 */
function sortFileFolder(a, b) {
  const aName = a.getAttribute('data-sort-name').toUpperCase();
  const bName = b.getAttribute('data-sort-name').toUpperCase();
  if (aName < bName) {
    return -1;
  } else if (aName > bName) {
    return 1;
  } else {
    return 0;
  }
}

/**
 * Resort and re-init
 */
function resortAndInit() {
  const folders = [...document.querySelectorAll('.js-folder')].sort(sortFileFolder);
  const files = [...document.querySelectorAll('.js-file')].sort(sortFileFolder);
  const container = document.querySelector('.js-fileUpload-items');
  [...folders, ...files].forEach((item) => container.appendChild(item));
  init();
}

function bindFileDelete() {
  [...document.querySelectorAll('.js-file-delete')].forEach((delBtn) => {
    const handler = () => {
      const fileId = parseInt(delBtn.getAttribute('data-id'), 10);
      const div = document.getElementById(`file--${fileId}`);
      const name = div.getAttribute('data-sort-name');
      Dialogs.confirm(`Möchtest du die Datei '${name}' wirklich löschen?`).then((conf) => {
        if (conf) {
          del('files_delete_file', {fileId}).then(() => div.remove());
        }
      });
    };
    delBtn.addEventListener('click', handler);
    disposables.push(() => delBtn.removeEventListener('click', handler));
  });
}

function bindFolderDelete() {
  [...document.querySelectorAll('.js-folder-delete')].forEach((delBtn) => {
    const handler = () => {
      const folderId = parseInt(delBtn.getAttribute('data-id'), 10);
      const div = document.getElementById(`folder--${folderId}`);
      const name = div.getAttribute('data-sort-name');
      Dialogs.confirm(`Möchtest du den Ordner '${name}' mit seinem gesamten Inhalt wirklich löschen?`).then((conf) => {
        if (conf) {
          del('files_delete_folder', {folderId}).then(() => div.remove());
        }
      });
    };
    delBtn.addEventListener('click', handler);
    disposables.push(() => delBtn.removeEventListener('click', handler));
  });
}

function bindPatchFolder() {
  [...document.querySelectorAll('.js-folder-edit')].forEach((btn) => {
    const handler = () => {
      const id = parseInt(btn.getAttribute('data-id'), 10);
      const div = btn.closest('.js-folder');
      const oldBoardEyesOnly = div.getAttribute('data-board-eyes-only') === '1';
      const parentBoardEyesOnly = div.getAttribute('data-parent-board-eyes-only') === '1';
      const oldName = div.getAttribute('data-sort-name');

      const boardEyesDiv = document.createElement('div');
      boardEyesDiv.style = 'display:flex;gap:1em;align-items:center;';
      const label = document.createElement('label');
      label.innerHTML = `Nur für Vorstand + Leitung sichtbar: `;
      label.style = 'cursor:pointer;';
      label.setAttribute('for', `board-eyes-checkbox-${id}`);
      const checkbox = document.createElement('input');
      checkbox.id = `board-eyes-checkbox-${id}`;
      checkbox.checked = oldBoardEyesOnly;
      checkbox.type = 'checkbox';
      boardEyesDiv.appendChild(label);
      boardEyesDiv.appendChild(checkbox);

      const options = {defaultValue: oldName};
      if (!parentBoardEyesOnly) {
        options.extraInputs = [boardEyesDiv];
      }

      Dialogs.prompt('Bitte gib einen neuen Namen ein:', options).then((newName) => {
        if (!newName) {
          return;
        }
        const newBoardEyesOnly = checkbox.checked;
        let changed = false;
        const data = {};
        if (newName !== oldName) {
          data.name = newName;
          changed = true;
        }
        if (newBoardEyesOnly !== oldBoardEyesOnly) {
          data.boardEyesOnly = newBoardEyesOnly;
          changed = true;
        }
        if (changed) {
          const prom = patch('files_folder_patch', {folderId: id}, data);
          prom.then((resp) => {
            if ('name' in resp) {
              div.querySelector('.file-title').innerHTML = resp.name;
              div.setAttribute('data-sort-name', resp.name);
            }
            if ('boardEyesOnly' in resp) {
              div.setAttribute('data-board-eyes-only', resp.boardEyesOnly ? '1' : '0');
              const icon = div.querySelector('.file-icon > i');
              if (icon) {
                icon.classList.remove('fa', 'fa-lock', 'far', 'fa-folder-open');
                icon.classList.add(...(resp.boardEyesOnly ? ['fa', 'fa-lock'] : ['far', 'fa-folder-open']));
              }
            }
            resortAndInit();
          });
        }
      });
    };
    btn.addEventListener('click', handler);
    disposables.push(() => btn.removeEventListener('click', handler));
  });
}

function bindRenameFile() {
  [...document.querySelectorAll('.js-file-rename')].forEach((btn) => {
    const handler = () => {
      const id = parseInt(btn.getAttribute('data-id'), 10);
      const div = btn.closest('.js-file');
      const oldName = div.getAttribute('data-sort-name');
      Dialogs.prompt('Bitte gib einen neuen Namen ein:', {defaultValue: oldName}).then((newName) => {
        if (newName && newName !== oldName) {
          const data = {name: newName};
          const prom = patch('files_file_rename', {fileId: id}, data);
          prom.then(({newName, url}) => {
            div.querySelector('.file-title').innerHTML = newName;
            div.setAttribute('data-sort-name', newName);
            div.querySelector('.file-a').href = url;
            resortAndInit();
          });
        }
      });
    };
    btn.addEventListener('click', handler);
    disposables.push(() => btn.removeEventListener('click', handler));
  });
}

function bindNewFolder() {
  const span = document.querySelector('.files-newFolder');
  const handler = () => {
    Dialogs.prompt('Bitte gebe einen Namen für den neuen Ordner an:').then((name) => {
      if (name) {
        if (document.querySelector(`.js-folder[data-sort-name="${name}"]`)) {
          Dialogs.alert('Dieser Ordnername existiert bereits!');
        } else {
          post('files_create_folder', {folderId: getFolderId()}, {name}).then((response) => {
            const tmp = document.createElement('div');
            tmp.innerHTML = response.dom;
            const newDom = tmp.children[0];
            document.querySelector('.js-fileUpload-items').appendChild(newDom);
            resortAndInit();
          });
        }
      }
    });
  };
  span.addEventListener('click', handler);
  disposables.push(() => span.removeEventListener('click', handler));
}

export function init() {
  destroy();
  if (document.querySelector('.js-page-files--editable')) {
    bindFileDelete();
    bindFolderDelete();
    bindNewFolder();
    bindPatchFolder();
    bindRenameFile();

    disposables.push(initDropArea(
      'files_add',
      {folderId: getFolderId()},
      resortAndInit, //onStackFinished
    ));
  }
}

export function destroy() {
  disposables.forEach((fn) => fn());
  disposables = [];
}
